import {
  Avatar,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import "./Settings.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import LanguageIcon from "@mui/icons-material/Language";
import CardCustom from "../../components/CardCustom/CardCustom";
import { useEffect, useState } from "react";
import { useHookstate } from "@hookstate/core";
import GlobalState from "../../global/GlobalStore";
import HelperFns from "../../global/HelperFns";
import { ReactComponent as SlackIcon } from "../../assets/svgs/slack-icon.svg";
import { ReactComponent as HubspotIcon } from "../../assets/svgs/hubspot-icon.svg";

const Settings = () => {
  const domains = useHookstate(GlobalState.settings.domains);
  const [inputDomainUrl, setInputDomainUrl] = useState("");
  const [inputDomainName, setInputDomainName] = useState("");
  const { isSlackConnected, isHubspotConnected } = useHookstate(
    GlobalState.settings
  ).get();
  const leftNavItemSelected = useHookstate(GlobalState.leftNavItemSelected);
  useEffect(() => {
    leftNavItemSelected.set("Integrations");
  }, []);

  const handleDeleteDomain = (id: string) => {
    const newDomains = HelperFns.getArrayFromState(domains.get() ?? [])?.filter(
      (item) => item.id !== id
    );
    domains.set(newDomains);
  };

  const handleAddDomain = () => {
    const newDomains = HelperFns.getArrayFromState(domains.get() ?? []);
    newDomains?.push({
      url: inputDomainUrl,
      name: inputDomainName,
      id: inputDomainUrl,
    });
    domains.set(newDomains);
    setInputDomainUrl("");
    setInputDomainName("");
  };

  return (
    <div className="settings-main">
      <div className="settings-heading">Integrations</div>
      <Grid container>
        <Grid item lg={10} xl={8}>
          <CardCustom>
            {isSlackConnected ? (
              <div className="settings-slack-connected">
                Slack Connection Status:{" "}
                <span className="settings-slack-connected-status">
                  {" "}
                  Connected
                </span>
              </div>
            ) : (
              <div className="settings-slack-connected">
                Slack Connection Status:{" "}
                <span className="settings-slack-connected-status-no">
                  Not Connected
                </span>
              </div>
            )}
            <a
              href={`https://slack.com/oauth/v2/authorize?scope=incoming-webhook&redirect_uri=https%3A%2F%2Fapp.${
                process.env.REACT_APP_PROD ? "" : "sandbox."
              }oneloop.ai&client_id=3571975480993.6506098839171`}
              className="slack-button"
            >
              <SlackIcon />
              Add OneLoop to Slack
            </a>
          </CardCustom>

          <CardCustom>
            {isHubspotConnected ? (
              <div className="settings-slack-connected">
                Hubspot Connection Status:{" "}
                <span className="settings-slack-connected-status">
                  {" "}
                  Connected
                </span>
              </div>
            ) : (
              <div className="settings-slack-connected">
                Hubspot Connection Status:{" "}
                <span className="settings-slack-connected-status-no">
                  Not Connected
                </span>
              </div>
            )}
            <a
              href={`https://app.hubspot.com/oauth/authorize?client_id=89af30f6-1bdb-4b6f-914b-504c1abcb1a7&redirect_uri=https://app.${
                process.env.REACT_APP_PROD ? "" : "sandbox."
              }oneloop.ai/integration/hubspot&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.owners.read`}
              className="slack-button"
            >
              <HubspotIcon width={24} />
              Connect to Hubspot
            </a>
          </CardCustom>

          <CardCustom style={{ display: "none" }}>
            <div className="card-custom-heading">Manage Domains</div>
            <List>
              {domains.get()?.map((item) => (
                <ListItem
                  id={item.id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteDomain(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <LanguageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item.name} secondary={item.url} />
                </ListItem>
              ))}
            </List>
            <Grid
              sx={{ marginTop: 0 }}
              container
              columnSpacing={2}
              rowSpacing={2}
              alignItems="center"
            >
              <Grid item lg={12} xl={5}>
                <TextField
                  size="small"
                  required
                  id="domain-url"
                  label="Domain URL"
                  placeholder="https://www.google.com"
                  value={inputDomainUrl}
                  fullWidth
                  InputLabelProps={{
                    style: { fontSize: "14px", lineHeight: "20px" },
                  }}
                  inputProps={{
                    style: { fontSize: "14px", lineHeight: "20px" },
                  }}
                  sx={{ backgroundColor: "#fff" }}
                  onChange={(e) => setInputDomainUrl(e.target.value)}
                />
              </Grid>
              <Grid item lg={12} xl={5}>
                <TextField
                  size="small"
                  required
                  id="domain-name"
                  label="Domain Name"
                  placeholder="Google"
                  value={inputDomainName}
                  fullWidth
                  InputLabelProps={{
                    style: { fontSize: "14px", lineHeight: "20px" },
                  }}
                  inputProps={{
                    style: { fontSize: "14px", lineHeight: "20px" },
                  }}
                  sx={{ backgroundColor: "#fff" }}
                  onChange={(e) => setInputDomainName(e.target.value)}
                />
              </Grid>
              <Grid item lg={12} xl={2}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAddDomain}
                >
                  Add Domain
                </Button>
              </Grid>
            </Grid>
          </CardCustom>
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;
