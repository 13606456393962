import "./TableCellComponents.scss";
import { Grid } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PlaceIcon from "@mui/icons-material/Place";
import BusinessIcon from "@mui/icons-material/Business";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const TableCellAccountDetails = (props: {
  companySize: string;
  industry: string;
  companyFounded: string;
  location: string;
  linkedinUrl: string;
}) => {
  const { companySize, industry, companyFounded, location, linkedinUrl } =
    props;
  return (
    <Grid
      container
      alignItems="center"
      rowSpacing={0.5}
      sx={{ maxWidth: "250px" }}
    >
      <Grid item xs={5} className="table-cell-a-details-item">
        <span className="table-cell-a-details-item-icon">
          <PeopleIcon fontSize="inherit" />
        </span>
        <span>{companySize}</span>
      </Grid>
      <Grid item xs={4} className="table-cell-a-details-item">
        <span className="table-cell-a-details-item-icon">
          <BusinessIcon fontSize="inherit" />
        </span>
        <span>{companyFounded}</span>
      </Grid>
      <Grid item xs={3} className="table-cell-a-details-item">
        <span className="table-cell-a-details-item-icon">
          <a
            href={linkedinUrl}
            target="_blank"
            rel="noreferrer"
            style={{ color: "#000" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <LinkedInIcon color="inherit" />
          </a>
        </span>
      </Grid>

      <Grid item xs={12} className="table-cell-a-details-item">
        <span className="table-cell-a-details-item-icon">
          <LocalOfferIcon fontSize="inherit" />
        </span>
        <span>{industry}</span>
      </Grid>
    </Grid>
  );
};
export default TableCellAccountDetails;
