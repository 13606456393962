import { ImmutableArray } from "@hookstate/core";
import { ActivityGraphType, GlobalConfidenceScoreType } from "./GlobalTypes";
import { AccountsTableRowType } from "../components/AccountsTable/AccountsTable";

class HelperFns {
  static getArrayFromState<T>(origArray: ImmutableArray<T>): T[] {
    return origArray.map((item) => {
      return { ...(item as object) } as T;
    });
  }

  static withHttp = (url: string) => {
    return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
  };

  static getLast7Dates = (): string[] => {
    // Get the current date
    const today: Date = new Date();

    // Create an array to store the last 7 dates
    const last7Dates: string[] = [];

    // Loop to generate the last 7 dates
    for (let i: number = 0; i < 7; i++) {
      const currentDate: Date = new Date(today);
      currentDate.setDate(today.getDate() - i);
      const year: number = currentDate.getFullYear();
      const month: string = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day: string = String(currentDate.getDate()).padStart(2, "0");
      last7Dates.push(`${year}-${month}-${day}`);
    }

    last7Dates.reverse();
    return last7Dates;
  };

  static fillAccountActivityData = (
    activity: ActivityGraphType[]
  ): ActivityGraphType[] => {
    const last7Dates: string[] = this.getLast7Dates();

    const filledActivity: ActivityGraphType[] = [];

    last7Dates.forEach((date) => {
      const foundActivity = activity.find((item) => item.date === date);
      if (foundActivity) {
        filledActivity.push(foundActivity);
      } else {
        filledActivity.push({
          date: date,
          count: 0,
        });
      }
    });

    return filledActivity;
  };

  static convertSecsToString = (secs: number): string => {
    let finalString = "";

    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs % 3600) / 60);
    const seconds = Math.floor(secs % 60);
    if (hours > 0) {
      finalString += hours + "h ";
    }
    if (minutes > 0) {
      finalString += minutes + "m ";
    }
    if (seconds > 0) {
      finalString += seconds + "s";
    }
    return finalString;
  };

  static delay = async (time: number) => {
    return new Promise((res) => {
      setTimeout(res, time);
    });
  };

  static downloadAccountsToCSV = (accounts: AccountsTableRowType[]) => {
    const filteredAccounts = accounts.map((account) => {
      return {
        id: account.id,
        name: account.accountName,
        website: account.accountWebsite,
        linkedinUrl: account.linkedinUrl,
        companySize: account.companySize,
        industry: account.industry,
        totalVisits: account.totalVisits,
        totalLeads: account.totalLeads,
        location: account.location,
        pageViews: account.pageViews?.map((pv) => pv.path).join(", ") ?? "",
        lastVisit: account.lastActivity,
        events: account.totalEvents,
        totalWebsiteDuration: account.totalWebsiteDuration,
      };
    });

    const replacer = (key: any, value: any) =>
      value === null
        ? ""
        : typeof value !== "string"
        ? JSON.stringify(value)
        : value; // specify how you want to handle null values here
    const header = Object.keys(filteredAccounts[0]);
    let csv = filteredAccounts.map((row: any) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    let csvStr = csv.join("\r\n") as string;

    const blob = new Blob([csvStr], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    const timestamp = (new Date().getTime() / 1000).toFixed(0);
    a.href = url;
    a.download = `oneloop-accounts__${timestamp}.csv`;
    a.click();
  };

  static getConfidenceScore = (
    scoreString?: string
  ): GlobalConfidenceScoreType => {
    if (scoreString === "very_high") {
      return {
        score: 5,
        confidence: "Very High",
      };
    } else if (scoreString === "high") {
      return {
        score: 4,
        confidence: "High",
      };
    } else if (scoreString === "medium") {
      return {
        score: 3,
        confidence: "Medium",
      };
    } else if (scoreString === "low") {
      return {
        score: 2,
        confidence: "Low",
      };
    } else {
      return {
        score: 1,
        confidence: "-",
      };
    }
  };
}

export default HelperFns;
