import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import "./LeadsTable.scss";
import TableCellLeadName from "../TableCellComponents/TableCellLeadName";
import TableCellAccountName from "../TableCellComponents/TableCellAccountName";
import {
  ActivityGraphType,
  GlobalLeadFilterType,
  GlobalLeadStatusType,
  GlobalPaidPlanType,
  TOTAL_EVENTS_MAX,
} from "../../global/GlobalTypes";
import TableCellLastActivity from "../TableCellComponents/TableCellLastActivity";
import { Button, Chip, Drawer } from "@mui/material";
import GlobalState from "../../global/GlobalStore";
import { useHookstate } from "@hookstate/core";
import { useState } from "react";
import LeadDetails from "../LeadDetails/LeadDetails";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ApiCalls from "../../global/ApiCalls";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
import TableCellLedDetails from "../TableCellComponents/TableCellLeadDetails";
import LeadsFilterPopover from "../FilterPopover/LeadsFilterPopover";
import TableCellLeadStatus from "../TableCellComponents/TableCellLeadStatus";

const filterOperator = {
  label: "mainFilter",
  value: "mainFilter",
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams<LeadsTableRowType, any>): boolean => {
      if (filterItem.value === undefined) return true;
      if (params.row === undefined) return true;

      const filterItemValue = filterItem.value as GlobalLeadFilterType;

      const results: boolean[] = [];
      if (filterItemValue?.leadName?.length) {
        results.push(filterItemValue.leadName.includes(params.row.leadName));
      }

      if (filterItemValue?.planTiers?.length) {
        results.push(
          filterItemValue.planTiers.includes(params.row.planTypeObj?.name ?? "")
        );
      }

      if (filterItemValue?.location?.length && params.row?.country?.length) {
        let localRes = false;
        for (let i in filterItemValue.location) {
          const location = filterItemValue.location[i];
          if (params.row.country.includes(location)) {
            localRes = true;
            break;
          }
        }
        results.push(localRes);
      }

      if (filterItemValue?.events?.length) {
        if (
          filterItemValue.events[0] === 0 &&
          filterItemValue.events[1] === TOTAL_EVENTS_MAX
        ) {
          results.push(true);
        } else if (
          filterItemValue.events[0] > 0 &&
          filterItemValue.events[1] === TOTAL_EVENTS_MAX
        ) {
          results.push(
            params.row.totalInteractions >= filterItemValue.events[0]
          );
        } else if (
          params.row.totalInteractions >= filterItemValue.events[0] &&
          params.row.totalInteractions <= filterItemValue.events[1]
        ) {
          results.push(true);
        } else {
          results.push(false);
        }
      }

      if (results.length === 0) return true;

      return results.every((result) => result);
      // return results.some((result) => result);
    };
  },
};

export type LeadsTableRowType = {
  id: string;
  accountId: string;
  leadName: string;
  leadEmail: string;
  accountName: string;
  accountWebsite: string;
  lastActivity: Date;
  linkedinUrl: string;
  country: string;
  title: string;
  isFavorite: boolean;
  totalInteractions: number;
  activityData: ActivityGraphType[];
  planTypeObj?: GlobalPaidPlanType;
  isEnriched: boolean;
  leadStatus?: GlobalLeadStatusType;
};

const columns: GridColDef[] = [
  {
    field: "isFavorite",
    headerName: "",
    width: 10,
    filterable: false,
    renderCell: (params: GridRenderCellParams<LeadsTableRowType, any>) => {
      const handleClick = (e: any) => {
        ApiCalls.toggleLeadFavorite(params.row.id);
        e.stopPropagation();
      };
      return (
        <div
          className={`${
            params.value
              ? "a-table-data-grid-cell-fav-show"
              : "a-table-data-grid-cell-fav"
          }`}
          onClick={handleClick}
        >
          {params.row.isFavorite ? <StarIcon /> : <StarBorderIcon />}
        </div>
      );
    },
  },
  {
    field: "id",
    headerName: "#",
    width: 50,
    filterOperators: [filterOperator],
    renderCell: (index) => (
      <div className="a-table-data-grid-cell">
        {index.api.getAllRowIds().indexOf(index.id) + 1}
      </div>
    ),
  },
  {
    field: "leadName",
    headerName: "Lead",
    width: 250,
    renderCell: (params: GridRenderCellParams<LeadsTableRowType, any>) => {
      return (
        <TableCellLeadName
          email={params.row?.leadEmail ?? ""}
          name={params.row?.leadName ?? ""}
          title={params.row?.title ?? ""}
        />
      );
    },
  },
  {
    field: "accountName",
    headerName: "Company",

    width: 250,
    valueGetter: (params) => {
      return {
        accountName: params.row.accountName,
        accountWebsite: params.row.accountWebsite,
      };
    },
    renderCell: (params: GridRenderCellParams<LeadsTableRowType, any>) => {
      return params.row.isEnriched ? (
        <TableCellAccountName
          accountName={params.row?.accountName ?? ""}
          accountWebsite={params.row?.accountWebsite ?? ""}
          accountId={params.row.accountId}
          logoUrl=""
        />
      ) : (
        <> </>
      );
    },
  },
  {
    field: "leadDetails",
    headerName: "Details",
    width: 150,
    valueGetter: (params: GridValueGetterParams<LeadsTableRowType, any>) => {
      return params.row.totalInteractions;
    },
    renderCell: (params: GridRenderCellParams<LeadsTableRowType, any>) => {
      if (!params.row) return <div></div>;
      return (
        <TableCellLedDetails
          linkedinUrl={params.row.linkedinUrl}
          location={params.row.country}
          title={params.row.title}
          totalInteractions={params.row.totalInteractions}
          planTypeObj={params.row.planTypeObj}
        />
      );
    },
  },
  {
    field: "planTypeObj",
    headerName: "Plan",
    minWidth: 70,
    renderCell: (params: GridRenderCellParams<LeadsTableRowType, any>) => {
      return (
        <div className="a-table-data-grid-cell">
          {params.row.planTypeObj ? (
            <Chip
              label={params.row.planTypeObj.name}
              size="small"
              color="primary"
              sx={{
                fontSize: "inherit",
                backgroundColor: params.row.planTypeObj.color,
                color: "#000",
                fontWeight: "bold",
              }}
            />
          ) : (
            ""
          )}
        </div>
      );
    },
  },
  {
    field: "leadStatus",
    headerName: "Status",
    minWidth: 150,
    renderCell: (params: GridRenderCellParams<LeadsTableRowType, any>) => {
      return (
        <TableCellLeadStatus
          status={params.row?.leadStatus ?? "-"}
          leadId={params.row.id}
        />
      );
    },
  },
  {
    field: "lastActivity",
    headerName: "Last Activity",
    headerClassName: "table-header-item",
    minWidth: 100,
    renderCell: (params: GridRenderCellParams<LeadsTableRowType, any>) => {
      return (
        <TableCellLastActivity
          lastActivity={params.row.lastActivity ?? new Date()}
        />
      );
    },
  },
  {
    field: "activityData",
    headerName: "Activity (Last 7 days)",
    minWidth: 150,
    renderCell: (params: GridRenderCellParams<LeadsTableRowType, any>) => {
      return (
        <ResponsiveContainer width="100%" height="50%">
          <AreaChart
            width={150}
            height={60}
            data={params.row.activityData ?? []}
          >
            <defs>
              <linearGradient id="colorUv" x1="1" y1="1" x2="1" y2="0">
                <stop offset="0%" stopColor="#8780FF" stopOpacity={0} />
                <stop offset="100%" stopColor="#8780FF" stopOpacity={1} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="count"
              strokeWidth={1}
              stroke="#8780FF"
              fill="url(#colorUv)"
              isAnimationActive={false}
            />
          </AreaChart>
        </ResponsiveContainer>
      );
    },
  },
];

const LeadsTable = ({ insightsEmails }: { insightsEmails?: string[] }) => {
  const leadsTableRows = useHookstate(GlobalState.leadsTableRows)
    .get()
    .filter((lead) => {
      if (insightsEmails && insightsEmails.length) {
        return insightsEmails.includes(lead.leadEmail);
      }
      return true;
    });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [leadIdToOpen, setLeadIdToOpen] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false);
  const globalLeadFilterState = useHookstate(GlobalState.leadsFilter);
  const globalLeadFilter = globalLeadFilterState.get();
  const leadFetchStatus = useHookstate(GlobalState.fetchStatus).get().leads;

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleRowClick = (leadId: string) => {
    setLeadIdToOpen(leadId);
    setDrawerOpen(true);
  };

  const handleFilterButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setFilterPopoverOpen(!filterPopoverOpen);
  };

  const getEventsFilterChipText = () => {
    if (globalLeadFilter?.events?.length) {
      if (
        globalLeadFilter.events[1] < TOTAL_EVENTS_MAX &&
        globalLeadFilter.events[0] > 0
      ) {
        return `Events: ${globalLeadFilter.events[0]} - ${globalLeadFilter.events[1]}`;
      } else if (globalLeadFilter.events[1] < TOTAL_EVENTS_MAX) {
        return `Events: <= ${globalLeadFilter.events[1]}`;
      } else if (globalLeadFilter.events[0] > 0) {
        return `Events: > ${globalLeadFilter.events[0]}`;
      }
    }
    return "";
  };

  return (
    <div className="a-table">
      <div className="a-table-filters">
        <Button onClick={handleFilterButtonClick}>Open Filter</Button>
        <LeadsFilterPopover
          anchorEl={anchorEl}
          open={filterPopoverOpen}
          handleClose={() => {
            setFilterPopoverOpen(false);
          }}
        />
        <div>
          {globalLeadFilter?.leadName?.map((name) => {
            return (
              <Chip
                label={name}
                sx={{ mr: 1, backgroundColor: "#e8eeff" }}
                onDelete={() => {
                  globalLeadFilterState.leadName.set(
                    (globalLeadFilter.leadName ?? []).filter(
                      (item) => item !== name
                    )
                  );
                }}
              />
            );
          })}
          {globalLeadFilter?.location?.map((name) => {
            return (
              <Chip
                label={name}
                sx={{ mr: 1, backgroundColor: "#BCEEBB" }}
                onDelete={() => {
                  globalLeadFilterState.location.set(
                    (globalLeadFilter.location ?? []).filter(
                      (item) => item !== name
                    )
                  );
                }}
              />
            );
          })}
          {globalLeadFilter?.planTiers?.map((name) => {
            return (
              <Chip
                label={name}
                sx={{ mr: 1, backgroundColor: "#EED0BB" }}
                onDelete={() => {
                  globalLeadFilterState.planTiers.set(
                    (globalLeadFilter.planTiers ?? []).filter(
                      (item) => item !== name
                    )
                  );
                }}
              />
            );
          })}
          {globalLeadFilter?.events?.length ? (
            <Chip
              label={getEventsFilterChipText()}
              sx={{ mr: 1, backgroundColor: "#EEECBB" }}
              onDelete={() => {
                globalLeadFilterState.events.set(undefined);
              }}
            />
          ) : null}
        </div>
      </div>

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <LeadDetails handleClose={handleDrawerClose} leadId={leadIdToOpen} />
      </Drawer>
      {leadFetchStatus && (
        <DataGrid
          rows={leadsTableRows}
          columns={columns}
          rowHeight={60}
          sx={{
            maxHeight: "calc(100vh - 120px)",
            "&&.MuiDataGrid-root .MuiDataGrid-cell": {
              border: "none",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .Mui-hovered": {
              cursor: "pointer",
              backgroundColor: "#F6F8FA !important",
            },
            "& .Mui-hovered .a-table-data-grid-cell-fav": {
              display: "block",
            },
            "& .MuiDataGrid-row": {
              borderBottom: "#DBE3EA 1px solid",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F6F8FA !important",

              border: "none",
              borderRadius: 2,
            },
            "& .MuiDataGrid-columnHeader--filtered button": {
              display: "none",
            },
          }}
          className="a-table-data-grid"
          disableRowSelectionOnClick
          onRowClick={(params) => handleRowClick(params.id as string)}
          filterModel={{
            items: [
              {
                id: 1,
                field: "id",
                operator: "mainFilter",
                value: globalLeadFilter,
              },
            ],
          }}
        />
      )}
    </div>
  );
};

export default LeadsTable;
