import ApiCalls from "../../global/ApiCalls";
import { ReactComponent as HubspotIcon } from "../../assets/svgs/hubspot-icon.svg";
import { useState } from "react";

const TableCellHubspotAdd = (props: { accountId: string }) => {
  const { accountId } = props;
  const [clicked, setClicked] = useState(false);

  return clicked ? (
    <div className="a-table-data-grid-cell-action-done">
      Added to <HubspotIcon height={16} />
    </div>
  ) : (
    <div
      className="a-table-data-grid-cell-action"
      onClick={(e) => {
        e.stopPropagation();
        ApiCalls.addAccountToHubspot(accountId);
        setClicked(true);
      }}
    >
      Add to <HubspotIcon height={16} />
    </div>
  );
};

export default TableCellHubspotAdd;
