import { GlobalStateType } from "./GlobalTypes";
import { hookstate } from "@hookstate/core";

const GlobalState = hookstate<GlobalStateType>({
  leftNavItemSelected: "Accounts",
  leadsTableRows: [],
  accountsTableRows: [],
  accountsFilter: {},
  leadsEventsData: {},
  leadsFilter: {},
  settings: {
    isSlackConnected: false,
    isHubspotConnected: false,
  },
  favorites: {
    favorites: [],
  },
  userDetails: {
    firstName: "",
    lastName: "",
  },
  fetchStatus: {
    leads: false,
    accounts: false,
  },
  savedAccountFilters: [],
});

export default GlobalState;
