const TableCellLastActivity = (props: { lastActivity: Date }) => {
  const { lastActivity } = props;
  const lastActivityTime = lastActivity.getTime();
  const nowTime = new Date().getTime();
  const diffTime = nowTime - lastActivityTime;

  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
  const diffMinutes = Math.floor(diffTime / (1000 * 60));
  const diffWeeks = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7));
  const diffMonths = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 30));

  let lastActivityString = "";

  if (diffMonths > 0) {
    lastActivityString = `${diffMonths} months ago`;
  } else if (diffWeeks > 0) {
    lastActivityString = `${diffWeeks} weeks ago`;
  } else if (diffDays > 0) {
    lastActivityString = `${diffDays} days ago`;
  } else if (diffHours > 0) {
    lastActivityString = `${diffHours} hours ago`;
  } else if (diffMinutes > 0) {
    lastActivityString = `${diffMinutes} minutes ago`;
  } else {
    lastActivityString = "Just now";
  }

  return (
    <div className="a-table-data-grid-cell">
      {lastActivityString}
      <br />
      <span style={{ fontSize: "10px" }}>
        {lastActivity.toLocaleDateString()}
      </span>
    </div>
  );
};

export default TableCellLastActivity;
