import "./FilterPopover.scss";
import {
  Autocomplete,
  Button,
  Grid,
  Popover,
  Slider,
  TextField,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PlaceIcon from "@mui/icons-material/Place";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { useEffect, useState } from "react";
import { useHookstate } from "@hookstate/core";
import GlobalState from "../../global/GlobalStore";
import {
  GlobalLeadFilterType,
  GlobalPaidPlans,
  TOTAL_EVENTS_MAX,
} from "../../global/GlobalTypes";

const LeadNameFilter = (props: {
  leadNames: string[];
  selectedLeadNames: string[];
  handleLeadNameChange: (names: string[]) => void;
}) => {
  const { leadNames, selectedLeadNames, handleLeadNameChange } = props;

  const handleLeadNameSelect = (event: any, value: string[]) => {
    handleLeadNameChange(value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="lead-name-filter"
        options={leadNames}
        value={selectedLeadNames}
        renderInput={(params) => <TextField {...params} label="Lead Name" />}
        onChange={handleLeadNameSelect}
        getOptionLabel={(option) => option}
        size="small"
        ListboxProps={{
          sx: { fontSize: 12 },
        }}
        sx={{
          minWidth: "150px",
          maxWidth: "400px",
          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
            fontSize: 12,
          },
          "& .MuiAutocomplete-tag": {
            fontSize: 12,
          },
        }}
      />
    </div>
  );
};

const LeadPlanFilter = (props: {
  leadPlans: string[];
  selectedLeadPlans: string[];
  handleLeadNameChange: (names: string[]) => void;
}) => {
  const { leadPlans, selectedLeadPlans, handleLeadNameChange } = props;

  const handleLeadPlanSelect = (event: any, value: string[]) => {
    handleLeadNameChange(value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="lead-name-filter"
        options={leadPlans}
        value={selectedLeadPlans}
        renderInput={(params) => <TextField {...params} label="Plan Type" />}
        onChange={handleLeadPlanSelect}
        getOptionLabel={(option) => option}
        size="small"
        ListboxProps={{
          sx: { fontSize: 12 },
        }}
        sx={{
          minWidth: "150px",
          maxWidth: "400px",
          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
            fontSize: 12,
          },
          "& .MuiAutocomplete-tag": {
            fontSize: 12,
          },
        }}
      />
    </div>
  );
};

const LeadLocationFilter = (props: {
  leadLocations: string[];
  selectedLeadLocations: string[];
  handleLeadLocationChange: (names: string[]) => void;
}) => {
  const { leadLocations, selectedLeadLocations, handleLeadLocationChange } =
    props;

  const handleLeadLocationSelect = (event: any, value: string[]) => {
    handleLeadLocationChange(value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="lead-location-filter"
        options={leadLocations}
        value={selectedLeadLocations}
        renderInput={(params) => (
          <TextField {...params} label="Lead Location" />
        )}
        onChange={handleLeadLocationSelect}
        getOptionLabel={(option) => option}
        size="small"
        ListboxProps={{
          sx: { fontSize: 12 },
        }}
        sx={{
          minWidth: "150px",
          maxWidth: "400px",
          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
            fontSize: 12,
          },
          "& .MuiAutocomplete-tag": {
            fontSize: 12,
          },
        }}
      />
    </div>
  );
};

const LeadTotalEventsFilter = (props: {
  selectedValues: number[];
  handleTotalEventsChange: (values: number[]) => void;
}) => {
  const { selectedValues, handleTotalEventsChange } = props;

  return (
    <div>
      <Slider
        aria-label="Total Events"
        valueLabelDisplay="auto"
        value={selectedValues}
        size="small"
        max={TOTAL_EVENTS_MAX}
        step={10}
        valueLabelFormat={(value) =>
          value === TOTAL_EVENTS_MAX ? "Max" : value
        }
        sx={{ width: "300px", margin: "20px auto" }}
        onChange={(e, newValues) =>
          handleTotalEventsChange(newValues as number[])
        }
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "12px",
          fontWeight: "500",
          width: "300px",
        }}
      >
        <div>Min Events: {selectedValues[0]}</div>
        <div>
          Max Events:{" "}
          {selectedValues[1] === TOTAL_EVENTS_MAX ? "Max" : selectedValues[1]}
        </div>
      </div>
    </div>
  );
};

type LeadFilterPopoverVisibleType =
  | "leadName"
  | "location"
  | "industry"
  | "planType"
  | "events";

const LeadsFilterPopover = (props: {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
}) => {
  const { anchorEl, open, handleClose } = props;
  const globalLeadFilter = useHookstate(GlobalState.leadsFilter);
  const globalLeadsResponse =
    useHookstate(GlobalState.leadsResponse).get() ?? [];
  const [localLeadFilter, setLocalLeadFilter] = useState<GlobalLeadFilterType>(
    {}
  );
  const [leadFilterVisible, setLeadFilterVisible] =
    useState<LeadFilterPopoverVisibleType>("leadName");

  useEffect(() => {
    setLocalLeadFilter({
      ...globalLeadFilter.get(),
      leadName: globalLeadFilter.get().leadName?.map((name) => name),
      title: globalLeadFilter.get().title?.map((title) => title),
      companySize: globalLeadFilter.get().companySize?.map((size) => size),
      companyLocation: globalLeadFilter
        .get()
        .companyLocation?.map((location) => location),
      industry: globalLeadFilter.get().industry?.map((industry) => industry),
      location: globalLeadFilter.get().location?.map((location) => location),
      planTiers: globalLeadFilter.get().planTiers?.map((tier) => tier),
      events: globalLeadFilter.get().events?.map((event) => event),
    });
  }, [globalLeadFilter]);

  const leadNames: string[] = [];
  globalLeadsResponse?.forEach((lead) => {
    const leadName = lead.firstName + " " + lead.lastName;
    if (leadName !== " " && !leadNames.includes(leadName)) {
      leadNames.push(leadName);
    }
  });

  const leadPlanTiers = GlobalPaidPlans.map((plan) => plan.name);

  const leadLocations: string[] = [];
  globalLeadsResponse?.forEach((lead) => {
    if (lead.country) {
      const allLocations = lead.country.split(",");
      allLocations.forEach((location) => {
        if (!leadLocations.includes(location)) {
          leadLocations.push(location);
        }
      });
    }
  });

  const handleApplyFilters = () => {
    globalLeadFilter.set(localLeadFilter);
    handleClose();
  };

  const handleClearFilters = () => {
    globalLeadFilter.set({});
    handleClose();
  };

  return (
    <Popover
      id="account-filter-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div className="filter-pop">
        <div className="filter-pop-header">
          Filters
          <div>
            <Button onClick={handleClearFilters} color="warning">
              Clear Filters
            </Button>
            <Button onClick={handleApplyFilters}>Apply Filters</Button>
          </div>
        </div>
        <Grid container>
          <Grid item xs={4}>
            <div className="filter-pop-left">
              <div
                className="filter-pop-left-item"
                style={{
                  fontWeight: leadFilterVisible === "leadName" ? "600" : "500",
                }}
                onClick={() => {
                  setLeadFilterVisible("leadName");
                }}
              >
                <BusinessIcon fontSize="inherit" />
                Lead Name
              </div>
              <div
                className="filter-pop-left-item"
                style={{
                  fontWeight: leadFilterVisible === "planType" ? "600" : "500",
                }}
                onClick={() => {
                  setLeadFilterVisible("planType");
                }}
              >
                <LocalOfferIcon fontSize="inherit" />
                Plan Type
              </div>
              <div
                className="filter-pop-left-item"
                style={{
                  fontWeight: leadFilterVisible === "location" ? "600" : "500",
                }}
                onClick={() => {
                  setLeadFilterVisible("location");
                }}
              >
                <PlaceIcon fontSize="inherit" />
                Location
              </div>
              <div
                className="filter-pop-left-item"
                style={{
                  fontWeight: leadFilterVisible === "events" ? "600" : "500",
                }}
                onClick={() => {
                  setLeadFilterVisible("events");
                }}
              >
                <AdsClickIcon fontSize="inherit" />
                Events
              </div>
            </div>
          </Grid>
          <Grid item xs={8} className="filter-pop-right">
            {leadFilterVisible === "leadName" && (
              <LeadNameFilter
                leadNames={leadNames}
                selectedLeadNames={localLeadFilter.leadName ?? []}
                handleLeadNameChange={(names) => {
                  setLocalLeadFilter({
                    ...localLeadFilter,
                    leadName: names,
                  });
                }}
              />
            )}
            {leadFilterVisible === "planType" && (
              <LeadPlanFilter
                leadPlans={leadPlanTiers}
                selectedLeadPlans={localLeadFilter.planTiers ?? []}
                handleLeadNameChange={(names) => {
                  setLocalLeadFilter({
                    ...localLeadFilter,
                    planTiers: names,
                  });
                }}
              />
            )}
            {leadFilterVisible === "location" && (
              <LeadLocationFilter
                leadLocations={leadLocations}
                selectedLeadLocations={localLeadFilter.location ?? []}
                handleLeadLocationChange={(names) => {
                  setLocalLeadFilter({
                    ...localLeadFilter,
                    location: names,
                  });
                }}
              />
            )}
            {leadFilterVisible === "events" && (
              <LeadTotalEventsFilter
                selectedValues={localLeadFilter.events ?? [0, TOTAL_EVENTS_MAX]}
                handleTotalEventsChange={(values) => {
                  setLocalLeadFilter({
                    ...localLeadFilter,
                    events: values,
                  });
                }}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </Popover>
  );
};

export default LeadsFilterPopover;
