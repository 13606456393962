import "./TableCellComponents.scss";
import { ReactComponent as HomeIcon } from "../../assets/svgs/home-icon.svg";
import { useState } from "react";
import { useHookstate } from "@hookstate/core";
import GlobalState from "../../global/GlobalStore";
import { useNavigate } from "react-router-dom";

const TableCellAccountName = (props: {
  accountName: string;
  accountWebsite: string;
  accountId?: string;
  logoUrl: string;
}) => {
  const { accountName, accountWebsite, accountId, logoUrl } = props;
  const accountsResponse = useHookstate(GlobalState.accountsResponse).get();
  const navigate = useNavigate();

  let fetchedUrl = logoUrl;

  if (fetchedUrl === "") {
    fetchedUrl =
      accountsResponse?.find((account) => account.accountId === accountId)
        ?.logoUrl ?? "";
  }

  const handleAccountClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!accountId) return;
    e.stopPropagation();
    navigate(`/account/${accountId}`);
  };

  return (
    <div className="table-cell-account" onClick={handleAccountClick}>
      <div className="table-cell-account-logo">
        {fetchedUrl ? (
          <img
            alt="Logo dark"
            src={fetchedUrl}
            className="table-cell-account-logo-image"
          />
        ) : (
          <HomeIcon width="30px" height="30px" />
        )}
      </div>
      <div>
        <div className="table-cell-account-name">{accountName}</div>

        <a
          href={accountWebsite}
          target="_blank"
          rel="noreferrer"
          className="table-cell-account-link"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="table-cell-account-url">{accountWebsite}</div>
        </a>
      </div>
    </div>
  );
};

export default TableCellAccountName;
