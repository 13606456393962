import "./Leads.scss";
import LeadsSnapshot from "../../components/Snapshot/LeadsSnapshot";
import GlobalState from "../../global/GlobalStore";
import { useHookstate } from "@hookstate/core";
import LeadsTable from "../../components/LeadsTable/LeadsTable";
import { useEffect } from "react";

function Leads() {
  const userDetails = useHookstate(GlobalState.userDetails).get();
  const leadsTableRows = useHookstate(GlobalState.leadsTableRows).get();
  const leftNavItemSelected = useHookstate(GlobalState.leftNavItemSelected);
  useEffect(() => {
    leftNavItemSelected.set("Leads");
  }, []);

  return (
    <div className="accounts-main">
      <div className="accounts-top-area">
        <div className="accounts-heading">Welcome {userDetails.firstName}!</div>
        <div className="accounts-subheading">
          Here is the weekly update of analysis of your report on dashboard.
        </div>
      </div>
      <LeadsSnapshot />
      <div className="accounts-table-main">
        {leadsTableRows?.length ? (
          <LeadsTable />
        ) : (
          <div className="accounts-no-results">There are no leads yet</div>
        )}
      </div>
    </div>
  );
}

export default Leads;
