import "./TableCellComponents.scss";

const TableCellLeadName = (props: {
  email: string;
  name: string;
  title: string;
}) => {
  const { email, name, title } = props;
  return (
    <div className="table-cell-lead">
      <div className="table-cell-lead-name">{name ? name : email}</div>
      {name && <div className="table-cell-lead-email">{email}</div>}
    </div>
  );
};

export default TableCellLeadName;
