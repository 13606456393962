import "./TableCellComponents.scss";
import { Grid } from "@mui/material";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PlaceIcon from "@mui/icons-material/Place";
import { GlobalPaidPlanType } from "../../global/GlobalTypes";

const TableCellLedDetails = (props: {
  title: string;
  location: string;
  linkedinUrl: string;
  totalInteractions: number;
  planTypeObj?: GlobalPaidPlanType;
}) => {
  const { title, totalInteractions, location, linkedinUrl, planTypeObj } =
    props;
  return (
    <Grid
      container
      alignItems="center"
      rowSpacing={0.5}
      sx={{ maxWidth: "200px" }}
    >
      <Grid item xs={6} className="table-cell-a-details-item">
        <span className="table-cell-a-details-item-icon">
          <AdsClickIcon fontSize="inherit" />
        </span>
        <span>{totalInteractions}</span>
      </Grid>
      {linkedinUrl && (
        <Grid item xs={6} className="table-cell-a-details-item">
          <span className="table-cell-a-details-item-icon">
            <a
              href={linkedinUrl}
              rel="noreferrer"
              target="_blank"
              style={{ color: "#000" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <LinkedInIcon color="inherit" />
            </a>
          </span>
        </Grid>
      )}
      {location && (
        <Grid item xs={12} className="table-cell-a-details-item">
          <span className="table-cell-a-details-item-icon">
            <PlaceIcon fontSize="inherit" />
          </span>
          <span>{location}</span>
        </Grid>
      )}
    </Grid>
  );
};
export default TableCellLedDetails;
