import { useHookstate } from "@hookstate/core";
import "./Snapshot.scss";
import GlobalState from "../../global/GlobalStore";

function LeadsSnapshot() {
  const statistics = useHookstate(GlobalState.statistics).get();

  return (
    <div className="leads-snapshot-main">
      <div className="leads-snapshot-item">
        <div className="leads-snapshot-item-heading">Identified Leads</div>
        <div className="leads-snapshot-item-value">{statistics?.warmLeads}</div>
        <div className="leads-snapshot-item-subheading"></div>
      </div>
      <div className="leads-snapshot-item">
        <div className="leads-snapshot-item-heading">
          Warm Accounts with Identified Leads
        </div>
        <div className="leads-snapshot-item-value">
          {statistics?.warmAccountsWithIdentifiedLeads}
        </div>
        <div className="leads-snapshot-item-subheading"></div>
      </div>
      <div className="leads-snapshot-item">
        <div className="leads-snapshot-item-heading">Total Interactions</div>
        <div className="leads-snapshot-item-value">
          {statistics?.totalEvents}
        </div>
        <div className="leads-snapshot-item-subheading"></div>
      </div>
    </div>
  );
}

export default LeadsSnapshot;
