import "./FilterPopover.scss";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  OutlinedInput,
  Popover,
  Slider,
  TextField,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import PlaceIcon from "@mui/icons-material/Place";
import TuneIcon from "@mui/icons-material/Tune";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TabIcon from "@mui/icons-material/Tab";
import { useEffect, useState } from "react";
import { useHookstate } from "@hookstate/core";
import GlobalState from "../../global/GlobalStore";
import {
  GlobalAccountFilterType,
  GlobalPaidPlans,
  SESSION_TIME_MAX,
  TOTAL_VISITS_MAX,
} from "../../global/GlobalTypes";
import HelperFns from "../../global/HelperFns";

const AccountNameFilter = (props: {
  accountNames: string[];
  selectedAccountNames: string[];
  handleAccountNameChange: (names: string[]) => void;
}) => {
  const { accountNames, selectedAccountNames, handleAccountNameChange } = props;

  const handleAccountNameSelect = (event: any, value: string[]) => {
    handleAccountNameChange(value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="account-name-filter"
        options={accountNames}
        value={selectedAccountNames}
        renderInput={(params) => <TextField {...params} label="Company Name" />}
        onChange={handleAccountNameSelect}
        getOptionLabel={(option) => option}
        size="small"
        ListboxProps={{
          sx: { fontSize: 12 },
        }}
        sx={{
          minWidth: "150px",
          maxWidth: "400px",
          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
            fontSize: 12,
          },
          "& .MuiAutocomplete-tag": {
            fontSize: 12,
          },
        }}
      />
    </div>
  );
};

const AccountLocationFilter = (props: {
  accountLocations: string[];
  selectedAccountLocations: string[];
  handleAccountLocationChange: (names: string[]) => void;
}) => {
  const {
    accountLocations,
    selectedAccountLocations,
    handleAccountLocationChange,
  } = props;

  const handleAccountLocationSelect = (event: any, value: string[]) => {
    handleAccountLocationChange(value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="account-location-filter"
        options={accountLocations}
        value={selectedAccountLocations}
        renderInput={(params) => (
          <TextField {...params} label="Company Location" />
        )}
        onChange={handleAccountLocationSelect}
        getOptionLabel={(option) => option}
        size="small"
        ListboxProps={{
          sx: { fontSize: 12 },
        }}
        sx={{
          minWidth: "150px",
          maxWidth: "400px",
          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
            fontSize: 12,
          },
          "& .MuiAutocomplete-tag": {
            fontSize: 12,
          },
        }}
      />
    </div>
  );
};

const AccountIndustryFilter = (props: {
  accountIndustry: string[];
  selectedAccountIndustry: string[];
  handleAccountLocationChange: (names: string[]) => void;
}) => {
  const {
    accountIndustry,
    selectedAccountIndustry,
    handleAccountLocationChange,
  } = props;

  const handleAccountLocationSelect = (event: any, value: string[]) => {
    handleAccountLocationChange(value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="account-location-filter"
        options={accountIndustry}
        value={selectedAccountIndustry}
        renderInput={(params) => (
          <TextField {...params} label="Company Industry" />
        )}
        onChange={handleAccountLocationSelect}
        getOptionLabel={(option) => option}
        size="small"
        ListboxProps={{
          sx: { fontSize: 12 },
        }}
        sx={{
          minWidth: "150px",
          maxWidth: "400px",
          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
            fontSize: 12,
          },
          "& .MuiAutocomplete-tag": {
            fontSize: 12,
          },
        }}
      />
    </div>
  );
};

const AccountTotalVisitsFilter = (props: {
  selectedValues: number[];
  handleTotalVisitsChange: (values: number[]) => void;
}) => {
  const { selectedValues, handleTotalVisitsChange } = props;

  return (
    <div>
      <Slider
        aria-label="Total Visits"
        valueLabelDisplay="auto"
        value={selectedValues}
        size="small"
        valueLabelFormat={(value) =>
          value === TOTAL_VISITS_MAX ? "Max" : value
        }
        sx={{ width: "300px", margin: "20px auto" }}
        onChange={(e, newValues) =>
          handleTotalVisitsChange(newValues as number[])
        }
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "12px",
          fontWeight: "500",
          width: "300px",
        }}
      >
        <div>Min Visits: {selectedValues[0]}</div>
        <div>
          Max Visits:{" "}
          {selectedValues[1] === TOTAL_VISITS_MAX ? "Max" : selectedValues[1]}
        </div>
      </div>
    </div>
  );
};

const AccountSessionTimeFilter = (props: {
  selectedValues: number[];
  handleSessionTimeChange: (values: number[]) => void;
}) => {
  const { selectedValues, handleSessionTimeChange } = props;

  return (
    <div>
      <Slider
        aria-label="Total Visits"
        valueLabelDisplay="auto"
        value={selectedValues}
        size="small"
        valueLabelFormat={(value) =>
          value === SESSION_TIME_MAX ? "Max" : value
        }
        sx={{ width: "300px", margin: "20px auto" }}
        onChange={(e, newValues) =>
          handleSessionTimeChange(newValues as number[])
        }
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "12px",
          fontWeight: "500",
          width: "300px",
        }}
      >
        <div>Min Session Time: {selectedValues[0]}</div>
        <div>
          Max Session Time:{" "}
          {selectedValues[1] === SESSION_TIME_MAX ? "Max" : selectedValues[1]}
        </div>
      </div>
    </div>
  );
};

const AccountPlanFilter = (props: {
  accountPlans: string[];
  selectedAccountPlans: string[];
  handlePlanChange: (names: string[]) => void;
}) => {
  const { accountPlans, selectedAccountPlans, handlePlanChange } = props;

  const handlePlanSelect = (event: any, value: string[]) => {
    handlePlanChange(value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="account-plan-filter"
        options={accountPlans}
        value={selectedAccountPlans}
        renderInput={(params) => <TextField {...params} label="Plan Type" />}
        onChange={handlePlanSelect}
        getOptionLabel={(option) => option}
        size="small"
        ListboxProps={{
          sx: { fontSize: 12 },
        }}
        sx={{
          minWidth: "150px",
          maxWidth: "400px",
          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
            fontSize: 12,
          },
          "& .MuiAutocomplete-tag": {
            fontSize: 12,
          },
        }}
      />
    </div>
  );
};

const AccountConfidenceFilter = (props: {
  confidenceScores: string[];
  selectedConfidenceScores: string[];
  handleConfidenceScoreChange: (names: string[]) => void;
}) => {
  const {
    confidenceScores,
    selectedConfidenceScores,
    handleConfidenceScoreChange,
  } = props;

  const handleConfidenceScoreSelect = (event: any, value: string[]) => {
    handleConfidenceScoreChange(value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="account-confidence-filter"
        options={confidenceScores}
        value={selectedConfidenceScores}
        renderInput={(params) => (
          <TextField {...params} label="Confidence Score" />
        )}
        onChange={handleConfidenceScoreSelect}
        getOptionLabel={(option) => option}
        size="small"
        ListboxProps={{
          sx: { fontSize: 12 },
        }}
        sx={{
          minWidth: "150px",
          maxWidth: "400px",
          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
            fontSize: 12,
          },
          "& .MuiAutocomplete-tag": {
            fontSize: 12,
          },
        }}
      />
    </div>
  );
};

const AccountPageFilter = (props: {
  accountPages: string[];
  selectedAccountPages: string[];
  handlePageChange: (names: string[]) => void;
}) => {
  const { accountPages, selectedAccountPages, handlePageChange } = props;

  const handlePageSelect = (event: any, value: string[]) => {
    handlePageChange(value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="account-page-filter"
        options={accountPages}
        value={selectedAccountPages}
        renderInput={(params) => <TextField {...params} label="Pages Viewed" />}
        onChange={handlePageSelect}
        getOptionLabel={(option) => option}
        size="small"
        ListboxProps={{
          sx: { fontSize: 12 },
        }}
        sx={{
          minWidth: "150px",
          maxWidth: "400px",
          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
            fontSize: 12,
          },
          "& .MuiAutocomplete-tag": {
            fontSize: 12,
          },
        }}
      />
    </div>
  );
};

type AccountFilterPopoverVisibleType =
  | "accountName"
  | "location"
  | "industry"
  | "totalVisits"
  | "sessionTime"
  | "planType"
  | "pageViews"
  | "confidenceScore";

const AccountFilterPopover = (props: {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
}) => {
  const { anchorEl, open, handleClose } = props;
  const globalAccountFilter = useHookstate(GlobalState.accountsFilter);
  const globalAccountsResponse = useHookstate(GlobalState.accountsResponse);
  const [localAccountFilter, setLocalAccountFilter] =
    useState<GlobalAccountFilterType>({});
  const [accountFilterVisible, setAccountFilterVisible] =
    useState<AccountFilterPopoverVisibleType>("accountName");

  const globalConfig = useHookstate(GlobalState.config).get()?.configuration;

  const accountNames: string[] = [];

  (globalAccountsResponse.get() ?? []).forEach((a) => {
    if (a.companyName && !accountNames.includes(a.companyName)) {
      accountNames.push(a.companyName);
    }
  });

  const accountLocations: string[] = [];

  (globalAccountsResponse.get() ?? []).forEach((a) => {
    if (a.companyLocation) {
      a.companyLocation.split(",").forEach((location) => {
        if (!accountLocations.includes(location)) {
          accountLocations.push(location);
        }
      });
    }
  });

  const accountIndustry: string[] = [];

  (globalAccountsResponse.get() ?? []).forEach((a) => {
    if (a.companyIndustry && !accountIndustry.includes(a.companyIndustry)) {
      accountIndustry.push(a.companyIndustry);
    }
  });

  const accountPlanTiers = GlobalPaidPlans.map((plan) => plan.name);

  const confidenceScores = ["Very High", "High", "Medium", "Low", "Very Low"];

  const accountPages: string[] = [];

  (globalAccountsResponse.get() ?? []).forEach((a) => {
    a.pageViews?.forEach((page) => {
      const pageName = page.path;
      if (pageName && !accountPages.includes(pageName)) {
        accountPages.push(pageName);
      }
    });
  });

  useEffect(() => {
    setLocalAccountFilter({
      ...globalAccountFilter.get(),
      accountName: globalAccountFilter.get().accountName?.map((name) => name),
      location: globalAccountFilter.get().location?.map((location) => location),
      industry: globalAccountFilter.get().industry?.map((industry) => industry),
      companySize: globalAccountFilter.get().companySize?.map((size) => size),
      sessionTime: globalAccountFilter.get().sessionTime?.map((time) => time),
      planTiers: globalAccountFilter.get().planTiers?.map((plan) => plan),
      accountPages: globalAccountFilter.get().accountPages?.map((page) => page),
      confidenceScore: globalAccountFilter
        .get()
        .confidenceScore?.map((score) => score),
      totalVisits: globalAccountFilter
        .get()
        .totalVisits?.map((visits) => visits),
    });
  }, [globalAccountFilter]);

  const handleApplyFilters = (filters: GlobalAccountFilterType) => {
    globalAccountFilter.set(filters);
    setLocalAccountFilter(filters);
  };

  const handleClearFilters = () => {
    globalAccountFilter.set({});
    setLocalAccountFilter({});
    handleClose();
  };

  return (
    <Popover
      id="account-filter-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div className="filter-pop">
        <div className="filter-pop-header">
          Filters
          <div>
            <Button onClick={handleClearFilters} color="warning">
              Clear Filters
            </Button>
            <Button onClick={handleClose}>Apply Filter</Button>
          </div>
        </div>
        <Grid container>
          <Grid item xs={4}>
            <div className="filter-pop-left">
              <div
                className="filter-pop-left-item"
                style={{
                  fontWeight:
                    accountFilterVisible === "accountName" ? "600" : "500",
                }}
                onClick={() => {
                  setAccountFilterVisible("accountName");
                }}
              >
                <BusinessIcon fontSize="inherit" />
                Company Name
              </div>
              <div
                className="filter-pop-left-item"
                style={{
                  fontWeight:
                    accountFilterVisible === "location" ? "600" : "500",
                }}
                onClick={() => {
                  setAccountFilterVisible("location");
                }}
              >
                <PlaceIcon fontSize="inherit" />
                Company Location
              </div>
              <div
                className="filter-pop-left-item"
                style={{
                  fontWeight:
                    accountFilterVisible === "industry" ? "600" : "500",
                }}
                onClick={() => {
                  setAccountFilterVisible("industry");
                }}
              >
                <TuneIcon fontSize="inherit" />
                Company Industry
              </div>
              <div
                className="filter-pop-left-item"
                style={{
                  fontWeight:
                    accountFilterVisible === "totalVisits" ? "600" : "500",
                }}
                onClick={() => {
                  setAccountFilterVisible("totalVisits");
                }}
              >
                <VisibilityIcon fontSize="inherit" />
                Total Visits
              </div>
              <div
                className="filter-pop-left-item"
                style={{
                  fontWeight:
                    accountFilterVisible === "sessionTime" ? "600" : "500",
                }}
                onClick={() => {
                  setAccountFilterVisible("sessionTime");
                }}
              >
                <TabIcon fontSize="inherit" />
                Session Time
              </div>
              {globalConfig?.isPLG && (
                <div
                  className="filter-pop-left-item"
                  style={{
                    fontWeight:
                      accountFilterVisible === "planType" ? "600" : "500",
                  }}
                  onClick={() => {
                    setAccountFilterVisible("planType");
                  }}
                >
                  <LocalOfferIcon fontSize="inherit" />
                  Plan Type
                </div>
              )}
              <div
                className="filter-pop-left-item"
                style={{
                  fontWeight:
                    accountFilterVisible === "pageViews" ? "600" : "500",
                }}
                onClick={() => {
                  setAccountFilterVisible("pageViews");
                }}
              >
                <TabIcon fontSize="inherit" />
                Page Views
              </div>
              <div
                className="filter-pop-left-item"
                style={{
                  fontWeight:
                    accountFilterVisible === "pageViews" ? "600" : "500",
                }}
                onClick={() => {
                  setAccountFilterVisible("confidenceScore");
                }}
              >
                <TabIcon fontSize="inherit" />
                Confidence Score
              </div>
            </div>
          </Grid>
          <Grid item xs={8} className="filter-pop-right">
            {accountFilterVisible === "accountName" && (
              <AccountNameFilter
                accountNames={accountNames}
                selectedAccountNames={localAccountFilter.accountName ?? []}
                handleAccountNameChange={(names) => {
                  handleApplyFilters({
                    ...localAccountFilter,
                    accountName: names,
                  });
                }}
              />
            )}
            {accountFilterVisible === "location" && (
              <AccountLocationFilter
                accountLocations={accountLocations}
                selectedAccountLocations={localAccountFilter.location ?? []}
                handleAccountLocationChange={(locations) => {
                  handleApplyFilters({
                    ...localAccountFilter,
                    location: locations,
                  });
                }}
              />
            )}

            {accountFilterVisible === "industry" && (
              <AccountIndustryFilter
                accountIndustry={accountIndustry}
                selectedAccountIndustry={localAccountFilter.industry ?? []}
                handleAccountLocationChange={(industry) => {
                  handleApplyFilters({
                    ...localAccountFilter,
                    industry: industry,
                  });
                }}
              />
            )}

            {accountFilterVisible === "totalVisits" && (
              <AccountTotalVisitsFilter
                selectedValues={
                  localAccountFilter.totalVisits ?? [0, TOTAL_VISITS_MAX]
                }
                handleTotalVisitsChange={(values) => {
                  handleApplyFilters({
                    ...localAccountFilter,
                    totalVisits: values,
                  });
                }}
              />
            )}

            {accountFilterVisible === "sessionTime" && (
              <AccountSessionTimeFilter
                selectedValues={
                  localAccountFilter.sessionTime ?? [0, SESSION_TIME_MAX]
                }
                handleSessionTimeChange={(values) => {
                  handleApplyFilters({
                    ...localAccountFilter,
                    sessionTime: values,
                  });
                }}
              />
            )}

            {accountFilterVisible === "planType" && (
              <AccountPlanFilter
                accountPlans={accountPlanTiers}
                selectedAccountPlans={localAccountFilter.planTiers ?? []}
                handlePlanChange={(names) => {
                  handleApplyFilters({
                    ...localAccountFilter,
                    planTiers: names,
                  });
                }}
              />
            )}

            {accountFilterVisible === "pageViews" && (
              <AccountPageFilter
                accountPages={accountPages}
                selectedAccountPages={localAccountFilter.accountPages ?? []}
                handlePageChange={(names) => {
                  handleApplyFilters({
                    ...localAccountFilter,
                    accountPages: names,
                  });
                }}
              />
            )}

            {accountFilterVisible === "confidenceScore" && (
              <AccountConfidenceFilter
                confidenceScores={confidenceScores}
                selectedConfidenceScores={
                  localAccountFilter.confidenceScore ?? []
                }
                handleConfidenceScoreChange={(names) => {
                  handleApplyFilters({
                    ...localAccountFilter,
                    confidenceScore: names,
                  });
                }}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </Popover>
  );
};

export default AccountFilterPopover;
