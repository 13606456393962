import axios from "axios";

const baseURL = process.env.REACT_APP_PROD
  ? "https://api.oneloop.ai"
  : "https://api.sandbox.oneloop.ai";

export const PandaClient = axios.create({
  baseURL,
  timeout: 10000,
});
