import { useHookstate, useHookstateEffect } from "@hookstate/core";
import CardCustom from "../CardCustom/CardCustom";
import "./LeadDetails.scss";
import GlobalState from "../../global/GlobalStore";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import PublicIcon from "@mui/icons-material/Public";
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from "@mui/lab";
import Close from "@mui/icons-material/Close";
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import HelperFns from "../../global/HelperFns";
import { useEffect, useState } from "react";
import ApiCalls from "../../global/ApiCalls";
import { LeadEventType } from "../../global/GlobalTypes";

interface EventType {
  eventName: string;
  eventType: "WEBSITE" | "PRODUCT";
  eventTime: number;
}

interface EventTimelineProps {
  events: EventType[];
}

interface LeadProductEvents {
  eventName: string;
  eventTime: number;
  eventProperties: Record<string, any>;
}

const EventTimeline = (props: EventTimelineProps) => {
  const { events } = props;

  const getEventIcon = (eventType: "WEBSITE" | "PRODUCT") => {
    switch (eventType) {
      case "WEBSITE":
        return <PublicIcon fontSize="small" style={{ color: "#097100" }} />;
      case "PRODUCT":
        return <LaptopMacIcon fontSize="small" style={{ color: "#bf7214" }} />;
      default:
        return null;
    }
  };

  return (
    <Timeline>
      {events.map((event, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
          >
            {new Date(event.eventTime).toLocaleString()}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="inherit">
              {getEventIcon(event.eventType)}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ m: "auto 0" }} align="right" variant="body2">
            <span>{event.eventName}</span>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

type LeadDetailsProps = {
  leadId: string;
  handleClose: () => void;
};

const LeadDetails = (props: LeadDetailsProps) => {
  const { leadId, handleClose } = props;
  const leadEventsResponse = useHookstate(GlobalState.leadsEventsData).get({
    noproxy: true,
  });
  let leadProductEvents: LeadProductEvents[] = (
    leadEventsResponse[leadId] ?? []
  )
    .map((event) => {
      return {
        eventName: event.eventName,
        eventTime: new Date(Number(event.timestamp)).getTime(),
        eventProperties: event.eventData,
      };
    })
    .sort((a, b) => b.eventTime - a.eventTime);

  useEffect(() => {
    const getLeadEvents = async () => {
      await ApiCalls.getLeadEvents(leadId);
    };

    getLeadEvents();
  }, [leadId]);

  const leadList = useHookstate(GlobalState.leadsResponse).get() ?? [];
  if (leadList.length === 0) return <div></div>;
  const lead = leadList.find(
    (lead) => lead.leadId === leadId || lead.email === leadId
  );
  if (!lead) return <div></div>;

  const leadName = lead.firstName + " " + lead.lastName;

  return (
    <div className="lead-details">
      <div className="lead-details-heading-main">
        {leadName !== " " ? leadName : lead.email}
        <div className="lead-details-button" onClick={handleClose}>
          <Close />
        </div>
      </div>
      <CardCustom className="lead-details-card">
        <div className="lead-details-card-left">
          <span>Name</span>
          <br />
          <span>Email</span>
          <br />
          <span>LinkedIn Url</span>
          <br />
          <span>Title</span>
          <br />
          <span>Company</span>
          <br />
          <span>Company Website</span>
          <br />
          <span>Company LinkedIn</span>
          <br />
          <span>Source</span>
          <br />
          <span>Created</span> <br />
          <span>Plan Type</span>
        </div>
        <div className="lead-details-card-right">
          <span>{leadName}</span>
          <br />
          <span>{lead.email}</span>
          <br />
          <a href={lead.linkedinUrl} target="_blank" rel="noreferrer">
            {lead.linkedinUrl}
          </a>
          <br />
          <span>{lead.title}</span>
          <br />
          <span>{lead.companyName}</span>
          <br />
          <a href={lead.companyWebsite} target="_blank" rel="noreferrer">
            {lead.companyWebsite}
          </a>
          <br />
          <a href={lead.companyLinkedinUrl} target="_blank" rel="noreferrer">
            {lead.companyLinkedinUrl}
          </a>
          <br />
          <span>{lead.channels.join(" ")}</span>
          <br />
          <span>{new Date(lead.created).toLocaleString()}</span>
          <br />
          <span>
            {lead.planTypeObj && (
              <Chip
                label={lead.planTypeObj.name}
                size="small"
                color="primary"
                sx={{
                  fontSize: "inherit",
                  backgroundColor: lead.planTypeObj.color,
                  color: "#000",
                  fontWeight: "bold",
                }}
              />
            )}
          </span>
        </div>
      </CardCustom>
      {/* <div className="lead-details-heading">Timeline</div>
      <EventTimeline
        events={[
          {
            eventName: "Visited Homepage",
            eventType: "WEBSITE",
            eventTime: 1706504169441,
          },
          {
            eventName: "Filter Search Initiated",
            eventType: "PRODUCT",
            eventTime: 1706504169441 - 1000 * 60 * 60 * 24, // 1 day before
          },
          {
            eventName: "Visited Pricing Page",
            eventType: "WEBSITE",
            eventTime: 1706504169441 - 1000 * 60 * 60 * 24 * 2, // 2 days before
          },
          {
            eventName: "Signed Up",
            eventType: "PRODUCT",
            eventTime: 1706504169441 - 1000 * 60 * 60 * 24 * 7, // 7 days before
          },
        ]}
      /> */}
      {leadProductEvents.length ? (
        <div>
          <div className="lead-details-heading">Product Events</div>
          {leadProductEvents.length ? (
            <TableContainer className="acc-details-table">
              <Table>
                <TableHead className="acc-details-table-header">
                  <TableRow>
                    <TableCell>Event Name</TableCell>
                    <TableCell>Event Time</TableCell>
                    <TableCell>Event Properties</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leadProductEvents.map((row, key) => (
                    <TableRow
                      key={key}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                        height: "64px",
                      }}
                    >
                      <TableCell width={200}>
                        <span style={{ fontWeight: 500 }}>{row.eventName}</span>
                      </TableCell>
                      <TableCell width={200}>
                        <span>{new Date(row.eventTime).toLocaleString()}</span>
                      </TableCell>
                      <TableCell width={400}>
                        <pre className="lead-details-code">
                          {JSON.stringify(row.eventProperties, null, 2)}
                        </pre>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div>No product events yet</div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default LeadDetails;
