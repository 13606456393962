import { Global } from "@emotion/react";
import { AccountsTableRowType } from "../components/AccountsTable/AccountsTable";
import { LeadsTableRowType } from "../components/LeadsTable/LeadsTable";
import GlobalState from "./GlobalStore";
import {
  AccountDetailsResponseType,
  AccountInfo,
  GlobalConfigResponseType,
  GlobalFavoriteResponseType,
  GlobalLeadStatusType,
  GlobalPaidPlans,
  LeadEventsResponseType,
  LeadInfoType,
  SavedFilterType,
} from "./GlobalTypes";
import HelperFns from "./HelperFns";
import { PandaClient } from "./PandaClient";

export default class ApiCalls {
  static initialize = async () => {
    await this.getCustomerConfig();
    this.getStatistics();
    await this.getFavorites();
    await this.getAccounts();
    await this.getLeads();
    this.getSavedFilters();
    this.getSlackIntegration();
    await this.getHubspotIntegration();
  };

  static getCustomerConfig = async () => {
    const customerConfigResponse = await PandaClient.get("/dashboard/config");
    const customerConfig =
      customerConfigResponse.data as GlobalConfigResponseType;

    GlobalState.config.set(customerConfig);
  };

  static getFavorites = async () => {
    const response = await PandaClient.get("/dashboard/favorite");
    const favorites = response.data as GlobalFavoriteResponseType;
    GlobalState.favorites.set(favorites);
  };

  static getStatistics = async () => {
    const statistics = GlobalState.statistics;

    const statisticsResponse = await PandaClient.get("/dashboard/statistics");
    statistics.set(statisticsResponse.data);
  };

  static getLeads = async () => {
    const leadsTableRows = GlobalState.leadsTableRows;
    const localLeadsResponse = GlobalState.leadsResponse;
    const leadsResponse = await PandaClient.get("/dashboard/leads");
    const leads = (leadsResponse?.data?.leads ?? []) as LeadInfoType[];

    leads.forEach((lead) => {
      if (lead.enriched) {
        lead.linkedinUrl = HelperFns.withHttp(lead.linkedinUrl);
        lead.companyWebsite = HelperFns.withHttp(lead.companyWebsite);
        lead.companyLinkedinUrl = HelperFns.withHttp(lead.companyLinkedinUrl);

        if (lead.companyName) {
          lead.companyName = lead.companyName
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");
        }

        lead.country = lead.country?.includes("linkedin") ? "-" : lead.country;
      }

      if (lead.firstName) {
        lead.firstName =
          lead.firstName[0].toUpperCase() + lead.firstName.slice(1);
      } else {
        lead.firstName = "";
      }
      if (lead.lastName) {
        lead.lastName = lead.lastName[0].toUpperCase() + lead.lastName.slice(1);
      } else {
        lead.lastName = "";
      }

      if (!lead.events?.events?.length) {
        lead.events = {
          events: [],
        };
      }
      lead.events.events = lead.events.events.filter((event) => {
        return event.eventId;
      });
      lead.planTypeObj = GlobalPaidPlans.find((plan) => {
        return plan.id === lead.planType;
      });
    });

    localLeadsResponse.set(leads);

    const favorites = GlobalState.favorites.get({ noproxy: true });

    const leadsTableRowsData: LeadsTableRowType[] = leads.map((lead) => {
      const lastActivityCalc = lead.latestActivityTime
        ? new Date(lead.latestActivityTime)
        : new Date(lead.created);
      return {
        id: lead.leadId ?? lead.email,
        accountId: lead.accountId,
        leadName: `${lead.firstName ?? ""} ${lead.lastName ?? ""}`,
        leadEmail: lead.email,
        planTypeObj: lead.planTypeObj,
        accountName: lead.companyName,
        accountWebsite: lead.companyWebsite,
        lastActivity: lastActivityCalc,
        linkedinUrl: lead.linkedinUrl,
        country: lead.country,
        title: lead.title,
        isFavorite: favorites?.favorites.find((f) => f.leadId === lead.leadId)
          ? true
          : false,
        totalInteractions: lead.totalEvents ?? 0,
        activityData: HelperFns.fillAccountActivityData(
          lead.activityGraph ?? []
        ),
        isEnriched: lead.enriched,
        leadStatus: lead.status,
      } as LeadsTableRowType;
    });

    leadsTableRows.set(leadsTableRowsData);

    this.getMoreLeads(
      leadsResponse?.data?.cursorId?.sortKey,
      leadsResponse?.data?.cursorId?.partitionKey
    );
  };

  static getAccounts = async () => {
    const accountsTableRows = GlobalState.accountsTableRows;
    const localAccountsResponse = GlobalState.accountsResponse;
    const accountsResponse = await PandaClient.get("/dashboard/accounts");
    const accounts = (accountsResponse?.data?.accounts ?? []) as AccountInfo[];
    accounts.forEach((account) => {
      account.companyLinkedinUrl = HelperFns.withHttp(
        account.companyLinkedinUrl
      );
      account.companyWebsite = HelperFns.withHttp(account.companyWebsite);
      if (account.companyName) {
        account.companyName = account.companyName
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
      }
    });

    localAccountsResponse.set(accounts);

    const favorites = GlobalState.favorites.get({ noproxy: true });

    const accountsTableRowsData: AccountsTableRowType[] = accounts.map(
      (account) => {
        const lastActivityCalc = account.latestActivityTime
          ? new Date(account.latestActivityTime)
          : new Date(account.created);

        return {
          id: account.accountId,
          accountName: account.companyName,
          accountWebsite: account.companyWebsite,
          totalLeads: account.identifiedLeadsCount ?? 0,
          totalVisits: account.totalWebsiteSessions ?? 0,
          lastActivity: lastActivityCalc,
          totalWebsiteDuration: account.totalWebsiteSessionsDuration ?? 0,
          companySize: account.companySize,
          industry: account.companyIndustry,
          companyFounded: account.companyFounded,
          linkedinUrl: account.companyLinkedinUrl,
          location: account.companyLocation,
          activityData: HelperFns.fillAccountActivityData(
            account.activityGraph ?? []
          ),
          isFavorite: favorites?.favorites.find(
            (f) => f.accountId === account.accountId
          )
            ? true
            : false,
          logoUrl: account.logoUrl ?? "",
          pageViews: account.pageViews ?? [],
          confidenceScore: HelperFns.getConfidenceScore(
            account.confidenceScore
          ),
        };
      }
    );

    accountsTableRows.set(accountsTableRowsData);

    this.getMoreAccounts(
      accountsResponse?.data?.cursorId?.sortKey,
      accountsResponse?.data?.cursorId?.partitionKey
    );
  };

  static getAccountDetails = async (accountId: string) => {
    const accountDetailsResponse = await PandaClient.get(
      `/dashboard/accounts/${accountId}`
    );

    const accountDetails =
      accountDetailsResponse.data as AccountDetailsResponseType;

    return accountDetails;
  };

  static addSlackIntegration = async (code: string) => {
    await PandaClient.post(`/integration/slack/${code}`);
  };

  static addHubspotIntegration = async (code: string) => {
    await PandaClient.get(`/integration/hubspot/${code}`);
  };

  static getSlackIntegration = async () => {
    const globalSettings = GlobalState.settings;

    const slackIntegrationResponse = await PandaClient.get(
      "/integration/slack"
    );

    globalSettings.isSlackConnected.set(
      slackIntegrationResponse?.data?.isSlackIntegrated ?? false
    );
  };

  static getHubspotIntegration = async () => {
    const globalSettings = GlobalState.settings;

    const slackIntegrationResponse = await PandaClient.get(
      "/integration/hubspot"
    );

    globalSettings.isHubspotConnected.set(
      slackIntegrationResponse?.data?.isHubspotIntegrated ?? false
    );
  };

  static getMoreAccounts = async (cursorSk: string, cursorPk: string) => {
    const accountsTableRows = GlobalState.accountsTableRows;
    const localAccountsResponse = GlobalState.accountsResponse;

    if (!cursorSk || !cursorPk || cursorSk === "null" || cursorPk === "null") {
      const sortedTableRows = HelperFns.getArrayFromState(
        accountsTableRows.get({ noproxy: true })
      ).sort((a, b) => {
        if (a.isFavorite !== b.isFavorite) {
          return a.isFavorite ? -1 : 1;
        }

        return b.lastActivity.getTime() - a.lastActivity.getTime();
      });

      accountsTableRows.set(sortedTableRows);
      GlobalState.fetchStatus.accounts.set(true);

      this.enrichAccountType();
      return;
    }

    const accountsResponse = await PandaClient.get(
      "/dashboard/accounts?cursorSK=" + cursorSk + "&cursorPK=" + cursorPk
    );
    const accounts = (accountsResponse?.data?.accounts ?? []) as AccountInfo[];
    accounts.forEach((account) => {
      account.companyLinkedinUrl = HelperFns.withHttp(
        account.companyLinkedinUrl
      );
      account.companyWebsite = HelperFns.withHttp(account.companyWebsite);
      if (account.companyName) {
        account.companyName = account.companyName
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
      }
    });
    if (localAccountsResponse.get({ noproxy: true })) {
      const newAccountsArray = HelperFns.getArrayFromState<AccountInfo>(
        localAccountsResponse.get({ noproxy: true }) ?? []
      );
      localAccountsResponse.set(newAccountsArray.concat(accounts));
    } else {
      localAccountsResponse.set(accounts);
    }

    const favorites = GlobalState.favorites.get({ noproxy: true });

    const accountsTableRowsData: AccountsTableRowType[] = accounts.map(
      (account) => {
        const lastActivityCalc = account.latestActivityTime
          ? new Date(account.latestActivityTime)
          : new Date(account.created);
        return {
          id: account.accountId,
          accountName: account.companyName,
          accountWebsite: account.companyWebsite,
          totalLeads: account.identifiedLeadsCount ?? 0,
          totalVisits: account.totalWebsiteSessions ?? 0,
          lastActivity: lastActivityCalc,
          companySize: account.companySize,
          industry: account.companyIndustry,
          companyFounded: account.companyFounded,
          linkedinUrl: account.companyLinkedinUrl,
          location: account.companyLocation,
          totalWebsiteDuration: account.totalWebsiteSessionsDuration ?? 0,
          activityData: HelperFns.fillAccountActivityData(
            account.activityGraph ?? []
          ),
          isFavorite: favorites?.favorites.find(
            (f) => f.accountId === account.accountId
          )
            ? true
            : false,
          logoUrl: account.logoUrl ?? "",
          pageViews: account.pageViews ?? [],
          confidenceScore: HelperFns.getConfidenceScore(
            account.confidenceScore
          ),
        };
      }
    );

    if (accountsTableRows.get({ noproxy: true })) {
      const newAccountsTableRows = HelperFns.getArrayFromState(
        accountsTableRows.get({ noproxy: true })
      );

      newAccountsTableRows.push(...accountsTableRowsData);
      accountsTableRows.set(newAccountsTableRows);
    } else {
      accountsTableRows.set(accountsTableRowsData);
    }

    await this.getMoreAccounts(
      accountsResponse?.data?.cursorId?.sortKey,
      accountsResponse?.data?.cursorId?.partitionKey
    );
  };

  static getMoreLeads = async (cursorSk: string, cursorPk: string) => {
    const leadsTableRows = GlobalState.leadsTableRows;
    const localLeadsResponse = GlobalState.leadsResponse;

    if (!cursorSk || !cursorPk || cursorSk === "null" || cursorPk === "null") {
      const sortedTableRows = HelperFns.getArrayFromState(
        leadsTableRows.get({ noproxy: true })
      ).sort((a, b) => {
        if (a.isFavorite !== b.isFavorite) {
          return a.isFavorite ? -1 : 1;
        }

        return b.lastActivity.getTime() - a.lastActivity.getTime();
      });

      leadsTableRows.set(sortedTableRows);
      GlobalState.fetchStatus.leads.set(true);

      this.enrichAccountType();
      return;
    }

    const leadsResponse = await PandaClient.get(
      "/dashboard/leads/?cursorSK=" + cursorSk + "&cursorPK=" + cursorPk
    );
    const leads = (leadsResponse?.data?.leads ?? []) as LeadInfoType[];
    leads.forEach((lead) => {
      if (lead.enriched) {
        lead.linkedinUrl = HelperFns.withHttp(lead.linkedinUrl);
        lead.companyWebsite = HelperFns.withHttp(lead.companyWebsite);
        lead.companyLinkedinUrl = HelperFns.withHttp(lead.companyLinkedinUrl);

        if (lead.companyName) {
          lead.companyName = lead.companyName
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");
        }

        lead.country = lead.country?.includes("linkedin") ? "-" : lead.country;
      }

      if (lead.firstName) {
        lead.firstName =
          lead.firstName[0].toUpperCase() + lead.firstName.slice(1);
      } else {
        lead.firstName = "";
      }
      if (lead.lastName) {
        lead.lastName = lead.lastName[0].toUpperCase() + lead.lastName.slice(1);
      } else {
        lead.lastName = "";
      }
      if (!lead.events?.events?.length) {
        lead.events = {
          events: [],
        };
      }
      lead.events.events = lead.events.events.filter((event) => {
        return event.eventId;
      });
      lead.planTypeObj = GlobalPaidPlans.find((plan) => {
        return plan.id === lead.planType;
      });
    });

    if (localLeadsResponse.get({ noproxy: true })) {
      const newLeadsArray = HelperFns.getArrayFromState<LeadInfoType>(
        localLeadsResponse.get({ noproxy: true }) ?? []
      );
      localLeadsResponse.set(newLeadsArray.concat(leads));
    } else {
      localLeadsResponse.set(leads);
    }

    const favorites = GlobalState.favorites.get({ noproxy: true });

    const leadsTableRowsData: LeadsTableRowType[] = leads.map((lead) => {
      const lastActivityCalc = lead.latestActivityTime
        ? new Date(lead.latestActivityTime)
        : new Date(lead.created);
      return {
        id: lead.leadId ?? lead.email,
        accountId: lead.accountId,
        leadName: `${lead.firstName} ${lead.lastName}`,
        leadEmail: lead.email,
        planTypeObj: lead.planTypeObj,
        accountName: lead.companyName,
        accountWebsite: lead.companyWebsite,
        lastActivity: lastActivityCalc,
        linkedinUrl: lead.linkedinUrl,
        country: lead.country,
        title: lead.title,
        isFavorite: favorites?.favorites.find((f) => f.leadId === lead.leadId)
          ? true
          : false,
        totalInteractions: lead.totalEvents ?? 0,
        activityData: HelperFns.fillAccountActivityData(
          lead.activityGraph ?? []
        ),
        isEnriched: lead.enriched,
        leadStatus: lead.status,
      } as LeadsTableRowType;
    });

    if (leadsTableRows.get({ noproxy: true })) {
      const newLeadsTableRows = leadsTableRows
        .get({ noproxy: true })
        .map((item) => {
          return {
            ...(item as object),
          } as LeadsTableRowType;
        });

      newLeadsTableRows.push(...leadsTableRowsData);
      leadsTableRows.set(newLeadsTableRows);
    } else {
      leadsTableRows.set(leadsTableRowsData);
    }

    await this.getMoreLeads(
      leadsResponse?.data?.cursorId?.sortKey,
      leadsResponse?.data?.cursorId?.partitionKey
    );
  };

  static getLeadEvents = async (leadId: string) => {
    const response = await PandaClient.get(`/dashboard/leads/${leadId}/events`);
    const LeadEventsResponse = response.data as LeadEventsResponseType;

    const globalLeadsEventsData = GlobalState.leadsEventsData;
    globalLeadsEventsData[leadId].set([]);

    globalLeadsEventsData[leadId].set(LeadEventsResponse.events);

    if (LeadEventsResponse.cursorId) {
      this.getMoreLeadEvents(
        leadId,
        LeadEventsResponse.cursorId.sortKey,
        LeadEventsResponse.cursorId.partitionKey
      );
    }
  };

  static getMoreLeadEvents = async (
    leadId: string,
    cursorSk: string,
    cursorPk: string
  ) => {
    if (!cursorSk || !cursorPk || cursorSk === "null" || cursorPk === "null") {
      return;
    }

    const response = await PandaClient.get(
      `/dashboard/leads/${leadId}/events?cursorSK=${cursorSk}&cursorPK=${cursorPk}`
    );
    const LeadEventsResponse = response.data as LeadEventsResponseType;

    const globalLeadsEventsData = GlobalState.leadsEventsData.get({
      noproxy: true,
    });

    GlobalState.leadsEventsData[leadId].set(
      globalLeadsEventsData[leadId].concat(LeadEventsResponse.events)
    );

    if (LeadEventsResponse.cursorId) {
      await this.getMoreLeadEvents(
        leadId,
        LeadEventsResponse.cursorId.sortKey,
        LeadEventsResponse.cursorId.partitionKey
      );
    }
  };

  static toggleAccountFavorite = async (accountId: string) => {
    const accounts = HelperFns.getArrayFromState(
      GlobalState.accountsTableRows.get({ noproxy: true }) ?? []
    );
    const account = accounts.find((account) => account.id === accountId);

    if (!account) {
      return;
    }

    account.isFavorite = !account.isFavorite;

    await PandaClient.post(`/dashboard/accounts/favorite`, {
      accountId: accountId,
      favorited: account.isFavorite,
    });

    GlobalState.accountsTableRows.set(accounts);
  };

  static toggleLeadFavorite = async (leadId: string) => {
    const leads = HelperFns.getArrayFromState(
      GlobalState.leadsTableRows.get({ noproxy: true }) ?? []
    );
    const lead = leads.find((lead) => lead.id === leadId);

    if (!lead) {
      return;
    }

    lead.isFavorite = !lead.isFavorite;

    await PandaClient.post(`/dashboard/leads/favorite`, {
      leadId: leadId,
      favorited: lead.isFavorite,
    });

    GlobalState.leadsTableRows.set(leads);
  };

  static enrichAccountType = () => {
    const fetchStatus = GlobalState.fetchStatus.get({ noproxy: true });
    if (!fetchStatus?.leads || !fetchStatus?.accounts) {
      return;
    }

    const accounts = HelperFns.getArrayFromState(
      GlobalState.accountsResponse.get({ noproxy: true }) ?? []
    );

    const accountsTableRows = HelperFns.getArrayFromState(
      GlobalState.accountsTableRows.get({ noproxy: true }) ?? []
    );

    const leads = HelperFns.getArrayFromState(
      GlobalState.leadsResponse.get({ noproxy: true }) ?? []
    );

    accounts.forEach((account) => {
      const allLeads = leads.filter(
        (lead) => lead.accountId === account.accountId
      );
      const accountTableRow = accountsTableRows.find(
        (row) => row.id === account.accountId
      );

      let totalEvents = 0;

      allLeads.forEach((lead) => {
        totalEvents += lead.totalEvents ?? 0;
      });

      if (totalEvents > 0) {
        account.totalEvents = totalEvents;
        if (accountTableRow) {
          accountTableRow.totalEvents = totalEvents;
        }
      }

      const highestPlanType = allLeads.length
        ? allLeads.reduce((prev, current) => {
            return (prev.planTypeObj?.ranking ?? 0) >
              (current.planTypeObj?.ranking ?? 0)
              ? prev
              : current;
          }).planTypeObj
        : undefined;

      if (highestPlanType) {
        account.planTypeObj = highestPlanType;
        if (accountTableRow) {
          accountTableRow.planTypeObj = highestPlanType;
        }
      }
    });

    GlobalState.accountsResponse.set(accounts);
    GlobalState.accountsTableRows.set(accountsTableRows);
  };

  static updateLeadStatus = async (
    leadId: string,
    status: GlobalLeadStatusType
  ) => {
    await PandaClient.post(`/dashboard/leads/status`, {
      status: status as string,
      leadId: leadId,
    });

    const leads = HelperFns.getArrayFromState(
      GlobalState.leadsResponse.get({ noproxy: true }) ?? []
    );

    leads.forEach((lead) => {
      if (lead.leadId === leadId) {
        lead.status = status;
      }
    });

    GlobalState.leadsResponse.set(leads);

    const leadsTableRows = HelperFns.getArrayFromState(
      GlobalState.leadsTableRows.get({ noproxy: true }) ?? []
    );

    leadsTableRows.forEach((lead) => {
      if (lead.id === leadId) {
        lead.leadStatus = status;
      }
    });

    GlobalState.leadsTableRows.set(leadsTableRows);
  };

  static getSavedFilters = async () => {
    const response = await PandaClient.get("/dashboard/user");
    if (!response.data?.user?.filters) {
      return;
    }
    const savedFilters = response.data.user.filters as SavedFilterType[];
    GlobalState.savedAccountFilters.set(savedFilters);
  };

  static saveFilter = async (filter: SavedFilterType) => {
    const localFilter = GlobalState.savedAccountFilters
      .get({ noproxy: true })
      .find((f) => f.filterName === filter.filterName);

    if (localFilter) {
      await PandaClient.delete("/dashboard/filters/", {
        data: { filterName: filter.filterName },
      });
    }

    await PandaClient.post("/dashboard/filters", filter);

    await this.getSavedFilters();
  };

  static deleteFilter = async (filterName: string) => {
    await PandaClient.delete("/dashboard/filters/", { data: { filterName } });

    await this.getSavedFilters();
  };

  static addAccountToHubspot = async (accountId: string) => {
    await PandaClient.post(`/dashboard/actions/crm`, {
      accountId,
    });
  };
}
