import {
  Chip,
  Drawer,
  Grid,
  LinearProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./AccountDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useHookstate } from "@hookstate/core";
import GlobalState from "../../global/GlobalStore";
import CardCustom from "../../components/CardCustom/CardCustom";
import { ReactComponent as LinkedInIcon } from "../../assets/svgs/linkedin-icon.svg";
import { ReactComponent as WebsiteIcon } from "../../assets/svgs/website-channel.svg";
import ApiCalls from "../../global/ApiCalls";
import ArrowBack from "@mui/icons-material/ArrowBack";
import LeadDetails from "../../components/LeadDetails/LeadDetails";
import HelperFns from "../../global/HelperFns";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import {
  AccountDetailsResponseType,
  GlobalLeadStatusType,
  GlobalPaidPlanType,
  LeadInfoType,
  VisitorsType,
} from "../../global/GlobalTypes";
import TableCellLeadStatus from "../../components/TableCellComponents/TableCellLeadStatus";

const visitorRows = [] as {
  id: number;
  name: string;
  sessionTime: number;
  lastSeen: string;
}[];

const convertSecondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secondsLeft = seconds - hours * 3600 - minutes * 60;
  let time = "";
  if (hours > 0) {
    time += `${hours}h `;
  }
  if (minutes > 0) {
    time += `${minutes}m `;
  }
  if (secondsLeft > 0) {
    time += `${secondsLeft}s`;
  }
  return time;
};

const generateLeadCircle = (fullName: string) => {
  const initials = fullName
    .split(/\s+/)
    .map((word, index, arr) =>
      index === 0 || index === arr.length - 1
        ? word.charAt(0).toUpperCase()
        : ""
    )
    .join("");

  return <span className="acc-details-leadCircle">{initials}</span>;
};

const AccountDetails = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const leadsResponse =
    useHookstate(GlobalState.leadsResponse).get({ noproxy: true }) ?? [];
  const accountsResponse = useHookstate(GlobalState.accountsResponse).get();
  const [accountDetails, setAccountDetails] =
    useState<AccountDetailsResponseType>();
  const accountId = pathname.split("/")[2];
  const [pageViews, setPageViews] = useState<
    {
      count: number;
      path: string;
    }[]
  >([]);
  const [totalPageViews, setTotalPageViews] = useState(0);

  const leadRows = [] as {
    id: string;
    name: string;
    email: string;
    events: number;
    lastSeen: string;
    status: GlobalLeadStatusType;
    planTypeObj?: GlobalPaidPlanType;
  }[];

  const [drawerOpen, setDrawerOpen] = useState(false);

  accountDetails?.visitors.sort((a, b) => {
    a.sessions.sort((a1, b1) => {
      return parseInt(b1.sessionStartTime) - parseInt(a1.sessionStartTime);
    });

    b.sessions.sort((a1, b1) => {
      return parseInt(b1.sessionStartTime) - parseInt(a1.sessionStartTime);
    });

    const aDate =
      a.sessions.length > 0 ? parseInt(a.sessions[0].sessionStartTime) : 0;
    const bDate =
      b.sessions.length > 0 ? parseInt(b.sessions[0].sessionStartTime) : 0;

    return bDate - aDate;
  });

  const totalVisits =
    accountsResponse?.find((a) => a.accountId === accountId)
      ?.totalWebsiteSessions ?? 0;

  const account = useHookstate(GlobalState.accountsResponse)
    .get()
    ?.find((account) => account.accountId === accountId);

  const leadsForThisAccount: LeadInfoType[] = [];
  accountDetails?.leads.forEach((lead) => {
    const foundLead = leadsResponse.find((l) => l.leadId === lead.leadId);
    if (foundLead) {
      leadsForThisAccount.push(JSON.parse(JSON.stringify(foundLead)));
    }
  });

  leadsForThisAccount.forEach((lead) => {
    const lastSeen = new Date(
      lead.latestActivityTime ?? lead.created
    ).getTime();

    leadRows.push({
      id: lead.leadId,
      name: `${lead.firstName} ${lead.lastName}`,
      email: lead.email,
      events: lead.totalEvents ?? 0,
      lastSeen: new Date(lastSeen).toLocaleString(),
      status: lead.status ?? "-",
      planTypeObj: lead.planTypeObj,
    });
  });

  leadRows.sort((a, b) => {
    const aDate = new Date(a.lastSeen).getTime();
    const bDate = new Date(b.lastSeen).getTime();
    return bDate - aDate || b.events - a.events;
  });

  const [leadIdToOpen, setLeadIdToOpen] = useState<string>("");

  useEffect(() => {
    const getAccountDetails = async () => {
      const accountDetailsResponse = await ApiCalls.getAccountDetails(
        accountId
      );
      if (accountDetailsResponse.pageViews) {
        setPageViews(accountDetailsResponse.pageViews);
      }

      let localPageVisitCount = 0;
      accountDetailsResponse.pageViews.forEach((page) => {
        localPageVisitCount += page.count;
      });
      setTotalPageViews(localPageVisitCount);
      setAccountDetails(accountDetailsResponse);
    };

    getAccountDetails();
  }, []);

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className="acc-details">
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <LeadDetails handleClose={handleDrawerClose} leadId={leadIdToOpen} />
      </Drawer>
      <div className="acc-details-back" onClick={handleClickBack}>
        <ArrowBack />
      </div>
      <Grid container sx={{ marginBottom: "80px" }}>
        <Grid item xs={12} lg={6}>
          <div className="acc-details-name">{account?.companyName}</div>
          <CardCustom className="acc-details-stats">
            <div className="acc-details-stats-item">
              <div>
                <span className="acc-details-stats-item-heading">
                  Total Visits:{" "}
                </span>
                <span>{totalVisits}</span>
              </div>
              {accountDetails?.leads.length ? (
                <div>
                  <span className="acc-details-stats-item-heading">
                    Total Leads:{" "}
                  </span>
                  <span>{accountDetails.leads.length}</span>
                </div>
              ) : (
                <></>
              )}
              {account?.totalEvents ? (
                <div>
                  <span className="acc-details-stats-item-heading">
                    Total Events:{" "}
                  </span>
                  <span>{account.totalEvents}</span>
                </div>
              ) : (
                <></>
              )}
            </div>
            {account?.planTypeObj && (
              <Chip
                label={account.planTypeObj.name}
                size="small"
                color="primary"
                sx={{
                  fontSize: "inherit",
                  backgroundColor: account.planTypeObj.color,
                  color: "#000",
                  fontWeight: "bold",
                }}
              />
            )}
            <div className="acc-details-stats-item">
              <a
                href={account?.companyLinkedinUrl}
                target="_blank"
                rel="noreferrer"
                className="acc-details-stats-link"
              >
                <LinkedInIcon width="32px" height="32px" />
              </a>
              <a
                href={account?.companyWebsite}
                target="_blank"
                rel="noreferrer"
                className="acc-details-stats-link"
              >
                <WebsiteIcon width="32px" height="32px" />
              </a>
            </div>
          </CardCustom>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container className="acc-details-info">
            <Grid item xs={5} className="acc-details-info-heading">
              Company Founded <br />
              Industry <br />
              Company Size <br />
              Location
            </Grid>
            <Grid item xs={7}>
              {account?.companyFounded} <br />
              {account?.companyIndustry} <br />
              {account?.companySize} <br />
              <span style={{ textTransform: "capitalize" }}>
                {account?.companyLocation}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {visitorRows.length > 0 && (
        <div>
          <div className="acc-details-heading">Visitors</div>
          <TableContainer className="acc-details-table">
            <Table>
              <TableHead className="acc-details-table-header">
                <TableRow>
                  <TableCell>Visitor Name</TableCell>
                  <TableCell align="right">Session Time</TableCell>
                  <TableCell align="right">Last Seen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visitorRows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      {convertSecondsToTime(row.sessionTime)}
                    </TableCell>
                    <TableCell align="right">{row.lastSeen}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <div className="acc-details-heading">Pages</div>
      {pageViews.length ? (
        <TableContainer className="acc-details-table">
          <Table>
            <TableHead className="acc-details-table-header">
              <TableRow>
                <TableCell>Page Name</TableCell>
                <TableCell align="right">Page Views</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageViews.map((row) => (
                <TableRow
                  key={row.path}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <span style={{ fontWeight: 500 }}>{row.path}</span>
                  </TableCell>
                  <TableCell align="right" width={200}>
                    <LinearProgress
                      variant="determinate"
                      value={(row.count / totalPageViews) * 100}
                      sx={{ height: "8px", marginBottom: "8px" }}
                    />

                    <span>{row.count}</span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div style={{ marginBottom: "80px" }}>No pages visited yet</div>
      )}

      {leadRows.length > 0 ? (
        <div>
          <div className="acc-details-heading">Lead Events</div>
          {leadRows.length ? (
            <TableContainer className="acc-details-table">
              <Table>
                <TableHead className="acc-details-table-header">
                  <TableRow>
                    <TableCell>Lead Name</TableCell>
                    <TableCell>Lead Email</TableCell>
                    <TableCell>Plan Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Total Events</TableCell>
                    <TableCell align="right">Last Event Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leadRows.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                        height: "64px",
                      }}
                      onClick={() => {
                        setLeadIdToOpen(row.id);
                        setDrawerOpen(true);
                      }}
                      hover
                    >
                      <TableCell component="th" scope="row">
                        <span style={{ fontWeight: 500 }}>
                          {generateLeadCircle(row.name)} {row.name}
                        </span>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <span>{row.email}</span>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.planTypeObj && (
                          <Chip
                            label={row.planTypeObj?.name}
                            size="small"
                            color="primary"
                            sx={{
                              fontSize: "inherit",
                              backgroundColor: row.planTypeObj?.color,
                              color: "#000",
                              fontWeight: "bold",
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <TableCellLeadStatus
                          status={row.status}
                          leadId={row.id}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <span>{row.events}</span>
                      </TableCell>
                      <TableCell align="right" width={200}>
                        <span>{row.lastSeen}</span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div>No leads yet</div>
          )}
        </div>
      ) : (
        <></>
      )}

      {accountDetails?.visitors.length ? (
        <div>
          <div className="acc-details-heading">Visitors</div>

          {accountDetails.visitors.map((visitor, index) => (
            <Accordion
              style={{
                marginBottom: "20px",
                border: "1px solid black",
                boxShadow: "none",
              }}
              defaultExpanded={index === 0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{ fontWeight: 600 }}
              >
                Anonymous Visitor {index + 1}
              </AccordionSummary>
              <AccordionDetails key={index}>
                <div>
                  <TableContainer>
                    <Table>
                      <TableHead className="acc-details-table-header">
                        <TableRow>
                          <TableCell>Session Time</TableCell>
                          <TableCell align="right">Pages Viewed</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {visitor.sessions.map((row) => (
                          <TableRow
                            key={row.path}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <span>
                                {new Date(
                                  parseInt(row.sessionStartTime)
                                ).toLocaleString()}
                              </span>
                            </TableCell>
                            <TableCell align="right" width={200}>
                              {row.pathArr?.map((path, index) => {
                                return (
                                  <div key={index}>
                                    <span>{path.path}</span>
                                  </div>
                                );
                              })}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AccountDetails;
