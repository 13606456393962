import { GridColDef } from "@mui/x-data-grid";
import { LeadsTableRowType } from "../components/LeadsTable/LeadsTable";
import { AccountsTableRowType } from "../components/AccountsTable/AccountsTable";

export type LeftNavType =
  | "Accounts"
  | "Leads"
  | "Insights"
  | "Integrations"
  | "Logout";

export type ActivityGraphType = {
  date: string;
  count: number;
};

export type LeadInfoType = {
  leadId: string;
  email: string;
  firstName: string;
  lastName: string;
  linkedinUrl: string;
  title: string;
  role: string;
  levels: string[];
  accountId: string;
  country: string;
  channels: string[];
  companyName: string;
  companyWebsite: string;
  companySize: string;
  companyFounded: string;
  companyIndustry: string;
  companyLinkedinUrl: string;
  companyLocation: string;
  created: string;
  enriched: boolean;
  events: {
    events: {
      eventId: string;
      eventName: string;
      eventData: any;
      pk: string;
      timestamp: string;
      leadId: string;
      created: string;
      eventSource: string;
      entity: string;
      modified: string;
      customerId: string;
    }[];
  };
  planType?: string;
  planTypeObj?: GlobalPaidPlanType;
  totalEvents?: number;
  latestActivityTime?: number;
  activityGraph?: ActivityGraphType[];
  status?: GlobalLeadStatusType;
};

export type AccountInfo = {
  pdlAccountId: string;
  companyName: string;
  companyWebsite: string;
  companySize: string;
  companyFounded: string;
  companyIndustry: string;
  companyLinkedinUrl: string;
  companyLocation: string;
  created: string;
  accountId: string;
  totalWebsiteSessions?: number;
  identifiedLeadsCount?: number;
  latestActivityTime?: number;
  activityGraph?: ActivityGraphType[];
  logoUrl?: string;
  totalWebsiteSessionsDuration?: number;
  planTypeObj?: GlobalPaidPlanType;
  totalEvents?: number;
  pageViews?: AccountPageViewsType[];
  confidenceScore?: string;
};

export type AccountPageViewsType = {
  count: number;
  path: string;
};

export type AccountDetailsResponseType = {
  accountId: string;
  pageViews: AccountPageViewsType[];
  leads: {
    pk: string;
    accountId: string;
    leadId: string;
    created: string;
    entity: string;
    modified: string;
    customerId: string;
  }[];
  visitors: VisitorsType[];
};

export type LeadEventType = {
  eventId: string;
  eventName: string;
  eventData: Record<string, any>;
  pk: string;
  timestamp: string;
  leadId: string;
  created: string;
  eventSource: string;
  entity: string;
  modified: string;
  customerId: string;
};

export type LeadEventsResponseType = {
  events: LeadEventType[];
  cursorId?: {
    sortKey: string;
    partitionKey: string;
  };
};

export type GlobalAccountFilterType = {
  accountName?: string[];
  companySize?: number[];
  industry?: string[];
  companyFounded?: string;
  location?: string[];
  linkedinUrl?: string;
  totalVisits?: number[];
  sessionTime?: number[];
  planTiers?: string[];
  accountPages?: string[];
  confidenceScore?: string[];
};

export type GlobalLeadFilterType = {
  leadName?: string[];
  title?: string[];
  companySize?: number[];
  companyLocation?: string[];
  industry?: string[];
  location?: string[];
  planTiers?: string[];
  events?: number[];
};

export type GlobalFavoriteResponseType = {
  favorites: {
    accountId?: string;
    leadId?: string;
    created: string;
    modified: string;
  }[];
};

export type GlobalConfigResponseType = {
  configuration: {
    isPLG: boolean;
    planType: string;
    includedDomains: string[];
  };
};

export type GlobalStateType = {
  leftNavItemSelected: LeftNavType;
  userDetails: {
    firstName: string;
    lastName: string;
  };
  leadsResponse?: LeadInfoType[];
  leadsTableRows: LeadsTableRowType[];
  leadsEventsData: Record<string, LeadEventType[]>;
  accountsResponse?: AccountInfo[];
  accountsTableRows: AccountsTableRowType[];
  accountsFilter: GlobalAccountFilterType;
  leadsFilter: GlobalLeadFilterType;
  settings: {
    domains?: {
      id: string;
      name: string;
      url: string;
    }[];
    isSlackConnected: boolean;
    isHubspotConnected: boolean;
  };
  statistics?: {
    warmAccountsWithIdentifiedLeads: number;
    warmLeads: number;
    warmAccounts: number;
    totalEvents: number;
    totalWebsiteSessions: number;
    totalWebsiteSessionsDuration: number;
  };
  fetchStatus: {
    leads: boolean;
    accounts: boolean;
  };
  config?: GlobalConfigResponseType;
  favorites: GlobalFavoriteResponseType;
  savedAccountFilters: SavedFilterType[];
};

export type SavedFilterType = {
  filterName: string;
  filterString: string;
};

export type ChannelType = "Website" | "Product" | "Social";

export type IntentScoreType = "A" | "B" | "C" | "-";

export const PathConstants = {
  HOME: "/",
  ACCOUNTS_DETAIL: "/account/*",
  LEADS: "/leads",
  INTEGRATIONS: "/integrations",
  INSIGHTS: "/insights",
};

export const SESSION_TIME_MAX = 100;
export const TOTAL_VISITS_MAX = 100;
export const TOTAL_EVENTS_MAX = 1000;

export type GlobalPaidPlanType = {
  id: string;
  name: string;
  color: string;
  ranking: number;
};

export const GlobalPaidPlans: GlobalPaidPlanType[] = [
  {
    id: "free_plan",
    name: "Free",
    color: "#b2fba5 ",
    ranking: 0,
  },

  {
    id: "professional_plan",
    name: "Professional",
    color: " #aaf0d1",
    ranking: 1,
  },

  {
    id: "team_plan",
    name: "Team",
    color: " #fe97cf",
    ranking: 2,
  },

  {
    id: "enterprise_plan",
    name: "Enterprise",
    color: " #bdb0d0",
    ranking: 3,
  },
];

export type GlobalConfidenceScoreType = {
  score: number;
  confidence: string;
};

export const globalLeadStatus = [
  "Qualified",
  "Unqualified",
  "Contacted",
  "CRM",
  "Closed",
  "-",
] as const;

export type GlobalLeadStatusType = (typeof globalLeadStatus)[number];

export type PathArrItemType = {
  path: string;
  ts: number;
};

export type VisitorSessionType = {
  sessionStartTime: string;
  ipAddress: string;
  accountId: string;
  path: string;
  created: string;
  visitorId: string;
  entity: string;
  pathArr: PathArrItemType[];
  sessionDuration: number;
  pk: string;
  sessionId: string;
  modified: string;
  referrerUrl: string;
  customerId: string;
};

export type VisitorsType = {
  totalSessions: string;
  pk: string;
  accountId: string;
  visitorId: string;
  created: string;
  entity: string;
  modified: string;
  customerId: string;
  sessions: VisitorSessionType[];
};
