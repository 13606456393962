import { useHookstate } from "@hookstate/core";
import "./Dashboard.scss";

import LeftNav from "./LeftNav/LeftNav";
import GlobalState from "../global/GlobalStore";
import Accounts from "./Accounts/Accounts";
import Leads from "./Leads/Leads";
import { useClerk } from "@clerk/clerk-react";
import { useEffect } from "react";
import { PandaClient } from "../global/PandaClient";
import ApiCalls from "../global/ApiCalls";
import Settings from "./Settings/Settings";
import posthog from "posthog-js";
import { Route, Routes, useNavigate } from "react-router-dom";
import { PathConstants } from "../global/GlobalTypes";
import AccountDetails from "./AccountDetails/AccountDetails";
import { ImageAspectRatio } from "@mui/icons-material";
import { ReactComponent as OneloopLogo } from "../assets/svgs/oneloop-logo-full-black.svg";
import InsightsPage from "./Insights/InsightsPage";

const Dashboard = () => {
  const leftNavSelected = useHookstate(GlobalState.leftNavItemSelected);
  const { signOut, session, user } = useClerk();
  const isLoading = useHookstate(true);
  const sessionId = useHookstate("");
  const urlParams = new URLSearchParams(window.location.search);
  const codeParam = urlParams.get("code");
  const navigate = useNavigate();
  const userDetails = useHookstate(GlobalState.userDetails);

  const performLogout = async () => {
    await signOut();
  };

  leftNavSelected.get() === "Logout" && performLogout();

  useEffect(() => {
    const addSlack = async () => {
      await ApiCalls.addSlackIntegration(codeParam ?? "");
      navigate(PathConstants.HOME);
    };

    const addHubspot = async () => {
      await ApiCalls.addHubspotIntegration(codeParam ?? "");
      navigate(PathConstants.HOME);
    };
    const getToken = async () => {
      isLoading.set(true);
      const token = await session?.getToken({
        template: "jwt",
      });

      PandaClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      userDetails.set({
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
      });
      if (codeParam) {
        if (window.location.pathname.includes("integration/hubspot")) {
          addHubspot();
        } else {
          addSlack();
        }
      }

      await ApiCalls.initialize();

      posthog.capture("logged-in", {
        user: user?.id,
        email: user?.emailAddresses[0]?.emailAddress,
        firstName: user?.firstName,
        lastName: user?.lastName,
        createdAt: user?.createdAt,
        updatedAt: user?.updatedAt,
        lastSignInAt: user?.lastSignInAt,
      });

      setTimeout(() => {
        isLoading.set(false);
      }, 200);
    };

    if (session && sessionId.get() !== session.id) {
      sessionId.set(session?.id ?? "");
      getToken();
    }
  }, []);

  return (
    <div>
      {isLoading.get() ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <OneloopLogo width="200px" />
          <div>Loading...</div>
        </div>
      ) : (
        <div className="dashboard">
          <div className="dashboard-left">
            <LeftNav />
          </div>
          <div className="dashboard-right" id="dashboard-right-id">
            <Routes>
              <Route path={PathConstants.HOME} element={<Accounts />} />
              <Route path={PathConstants.LEADS} element={<Leads />} />
              <Route path={PathConstants.INSIGHTS} element={<InsightsPage />} />
              <Route path={PathConstants.INTEGRATIONS} element={<Settings />} />
              <Route
                path={PathConstants.ACCOUNTS_DETAIL}
                element={<AccountDetails />}
              />
            </Routes>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
