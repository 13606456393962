import { useHookstate } from "@hookstate/core";
import "./Snapshot.scss";
import GlobalState from "../../global/GlobalStore";
import HelperFns from "../../global/HelperFns";

function AccountsSnapshot() {
  const statistics = useHookstate(GlobalState.statistics).get();

  return (
    <div className="accounts-snapshot-main">
      <div className="accounts-snapshot-item">
        <div className="accounts-snapshot-item-heading">Warm Accounts</div>
        <div className="accounts-snapshot-item-value">
          {statistics?.warmAccounts}
        </div>
        <div className="accounts-snapshot-item-subheading"></div>
      </div>
      <div className="accounts-snapshot-item">
        <div className="accounts-snapshot-item-heading">
          Total Website Sessions
        </div>
        <div className="accounts-snapshot-item-value">
          {statistics?.totalWebsiteSessions}
        </div>
        <div className="accounts-snapshot-item-subheading"></div>
      </div>
      <div className="accounts-snapshot-item">
        <div className="accounts-snapshot-item-heading">
          Avg Website Duration
        </div>
        <div className="accounts-snapshot-item-value">
          {statistics &&
            HelperFns.convertSecsToString(
              statistics.totalWebsiteSessionsDuration /
                statistics.totalWebsiteSessions
            )}
        </div>
        <div className="accounts-snapshot-item-subheading"></div>
      </div>
    </div>
  );
}

export default AccountsSnapshot;
