import { MenuItem, Select } from "@mui/material";
import {
  GlobalLeadStatusType,
  globalLeadStatus,
} from "../../global/GlobalTypes";
import { useState } from "react";
import ApiCalls from "../../global/ApiCalls";

const TableCellLeadStatus = (props: {
  status: GlobalLeadStatusType;
  leadId: string;
}) => {
  const [status, setStatus] = useState<GlobalLeadStatusType>(props.status);

  return (
    <Select
      labelId="lead-status-cell-select-label"
      id="lead-status-cell-select"
      value={status}
      size="small"
      onChange={async (e) => {
        e.stopPropagation();
        setStatus(e.target.value as GlobalLeadStatusType);
        await ApiCalls.updateLeadStatus(
          props.leadId,
          e.target.value as GlobalLeadStatusType
        );
      }}
      sx={{
        width: "150px",
        height: "26px",
        lineHeight: "26px",
        fontSize: "12px",
      }}
    >
      {globalLeadStatus.map((status) => (
        <MenuItem
          key={status}
          value={status}
          sx={{
            fontSize: "12px",
          }}
        >
          {status}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TableCellLeadStatus;
