import "./Accounts.scss";
import AccountsSnapshot from "../../components/Snapshot/AccountsSnapshot";
import AccountsTable from "../../components/AccountsTable/AccountsTable";
import GlobalState from "../../global/GlobalStore";
import { useHookstate } from "@hookstate/core";
import { useEffect } from "react";

function Accounts() {
  const userDetails = useHookstate(GlobalState.userDetails).get();
  const accountsTableRows = useHookstate(GlobalState.accountsTableRows).get();
  const leftNavItemSelected = useHookstate(GlobalState.leftNavItemSelected);
  useEffect(() => {
    leftNavItemSelected.set("Accounts");
  }, []);
  return (
    <div className="accounts-main">
      <div className="accounts-top-area">
        <div className="accounts-heading">Welcome {userDetails.firstName}!</div>
        <div className="accounts-subheading">
          Here is the weekly update of analysis of your report on dashboard.
        </div>
      </div>
      <AccountsSnapshot />
      <div className="accounts-table-main">
        {accountsTableRows?.length ? (
          <AccountsTable />
        ) : (
          <div className="accounts-no-results">There are no accounts yet</div>
        )}
      </div>
    </div>
  );
}

export default Accounts;
