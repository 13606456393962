import "./LeftNav.scss";
import { ReactComponent as HomeIcon } from "../../assets/svgs/home-icon.svg";
import { ReactComponent as ContributionsIcon } from "../../assets/svgs/contributions-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/svgs/logout-icon.svg";
import { ReactComponent as IntegrationsIcon } from "../../assets/svgs/integrations-icon.svg";
import { ReactComponent as OneloopLogo } from "../../assets/svgs/oneloop-logo-full-black.svg";
import PeopleIcon from "@mui/icons-material/People";

import React, { useEffect } from "react";
import classNames from "classnames";
import { useHookstate } from "@hookstate/core";
import { LeftNavType, PathConstants } from "../../global/GlobalTypes";
import GlobalState from "../../global/GlobalStore";
import posthog from "posthog-js";
import { useClerk } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

interface LeftNavItemPropType {
  id: LeftNavType;
  name: string;
}

const items: Record<"topItems" | "bottomItems", LeftNavItemPropType[]> = {
  topItems: [
    {
      id: "Accounts",
      name: "Accounts",
    },
    {
      id: "Leads",
      name: "Leads",
    },
    {
      id: "Integrations",
      name: "Integrations",
    },
  ],
  bottomItems: [
    {
      id: "Logout",
      name: "Logout",
    },
  ],
};

const LeftNavItem = (props: LeftNavItemPropType & { isActive: boolean }) => {
  const { id, name, isActive } = props;
  const navigate = useNavigate();

  const CustomIcon = () => {
    if (id === "Accounts") return <HomeIcon width="18px" />;
    if (id === "Leads")
      return (
        <span style={{ fontSize: "18px" }}>
          <PeopleIcon fontSize="inherit" />
        </span>
      );
    if (id === "Insights") return <ContributionsIcon width="18px" />;
    if (id === "Integrations") return <IntegrationsIcon width="18px" />;
    if (id === "Logout") return <LogoutIcon width="18px" />;
    return <></>;
  };

  const { user } = useClerk();

  const selectedItem = useHookstate(GlobalState.leftNavItemSelected);

  const handleClick = () => {
    posthog.capture("menu-item-clicked", {
      userDetails: {
        user: user?.id,
        email: user?.emailAddresses[0]?.emailAddress,
        firstName: user?.firstName,
        lastName: user?.lastName,
      },
      menuItemClicked: id,
    });
    selectedItem.set(id);
    if (id === "Accounts") {
      navigate(PathConstants.HOME);
    } else if (id === "Leads") {
      navigate(PathConstants.LEADS);
    } else if (id === "Integrations") {
      navigate(PathConstants.INTEGRATIONS);
    } else if (id === "Insights") {
      navigate(PathConstants.INSIGHTS);
    }
  };

  const leftNavItemClassnames = classNames({
    "leftnav-item": true,
    "leftnav-item-active": isActive,
  });

  return (
    <div onClick={handleClick} className={leftNavItemClassnames}>
      <CustomIcon /> {name}
    </div>
  );
};

const LeftNav = () => {
  const selectedItem = useHookstate(GlobalState.leftNavItemSelected);
  const topItems = useHookstate(items.topItems);
  const bottomItems = useHookstate(items.bottomItems);

  const leads = useHookstate(GlobalState.leadsResponse).get();

  useEffect(() => {
    if (!leads?.length) {
      const filteredItems = items.topItems.filter(
        (item) => item.id !== "Leads"
      );
      topItems.set(filteredItems);
    } else {
      topItems.set(items.topItems);
    }
  }, [leads?.length]);

  return (
    <div className="leftnav">
      <div className="leftnav-logo">
        <OneloopLogo width={150} />
      </div>
      <div className="leftnav-items">
        <div className="leftnav-items-top">
          <div>
            {topItems.get()?.map((item) => (
              <LeftNavItem
                key={item.id}
                {...item}
                isActive={item.id === selectedItem.get()}
              />
            ))}
          </div>
        </div>

        <div className="leftnav-items-bottom">
          <div className="leftnav-items-bottom-border" />
          {bottomItems.get()?.map((item) => (
            <LeftNavItem
              key={item.id}
              {...item}
              isActive={item.id === selectedItem.get()}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LeftNav;
