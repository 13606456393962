import { useHookstate } from "@hookstate/core";
import LeadsTable from "../../components/LeadsTable/LeadsTable";
import GlobalState from "../../global/GlobalStore";

const insightEmails = {
  notActivatedUsers: [
    "akhilsachdev@berkeley.edu",
    "it.rex001@gmail.com",
    "is.k.ay.a.ra.n.o@gmail.com",
    "info@competitiverecruiting.de",
    "iska.y.a.r.a.no@gmail.com",
    "k.levada07@gmail.com",
    "graham@dgtlpartners.com",
    "willschrepf@gmail.com",
    "yokesh2102@gmail.com",
    "vynliza@gmail.com",
    "yurii.mostovyi@teaminternational.com",
    "firnash@gmail.com",
    "andrii.andriienko89@gmail.com",
    "shubham.s@cognavi.com",
    "nazarpetra13@gmail.com",
    "dari.zubenko@sdh.com.ua",
    "omotayokayodesamson@gmail.com",
    "mercedesz.abranyi@flex.com",
    "cameron.rumph@hawkeyeinnovations.com",
    "lynnkh555@gmail.com",
    "marsos1990@gmail.com",
    "em.a.ya.ana.nd26.4@gmail.com",
    "pellosophical@gmail.com",
    "maartenbanis@gmail.com",
    "valentine_lilas@hotmail.fr",
    "elvico279@gmail.com",
    "mahdihagi01@gmail.com",
    "ian+jbox@ianserlin.com",
    "christine@vectorrecruiting.com",
    "jhy10926@gmail.com",
    "lucas.weynars@employmenthero.com",
    "karinaqwertyyy@gmail.com",
    "jugal@hirequotient.com",
    "tim.chattaway@hawkeyeinnovations.com",
    "k.gutierrez.capefront@gmail.com",
    "davidacce@gmail.com",
    "clara.lsnc@gmail.com",
    "yaroslavashekerun@gmail.com",
    "abgrallkefran@gmail.com",
    "ilze.indrevica@gmail.com",
    "johannesritz@gmx.de",
    "wouter.brasem@businessmodelsinc.com",
    "polina@oakslab.com",
    "barbarabuttow@gmail.com",
    "aryan.aghili@gmail.com",
    "aliona@youteam.co.uk",
    "tal.shoham@lusha.com",
    "brad.bloom@morressier.com",
    "estela.regis@innocv.com",
    "george.hrstic@checkout.com",
    "wilfhoward15@gmail.com",
    "lharvey@zanetech.com",
    "kristine.sixeleven@gmail.com",
    "campbellw@mweb.co.za",
    "aya@foonda.co",
    "earl@activatetalent.com",
    "ejdeasis9999@gmail.com",
    "briannainmexico@gmail.com",
    "olhabiro@gmail.com",
    "c-jason.ling@masterclass.com",
    "alex@daybreakhealth.com",
    "oceaniamoragochatgpt@gmail.com",
    "jinyoung.park@dramancompany.com",
    "shushanik.naghdalyan@gmail.com",
    "hr@mediagroupww.com",
    "mg@mein-personaler.at",
    "a.pilhun@sohina.com.ua",
    "elisa.lacroix@prolipse.com",
    "harpaul@getmagical.com",
    "scott@veterantalent.com",
    "kristi.walthall@gmail.com",
    "anastasia.tarasova@transferwise.com",
    "axel.richard@iscparis.com",
    "k.daria_hr@spinbetter.com",
    "darpin.yeranosoglu@justlife.com",
    "laura@bellecares.com",
    "patelraghav32@gmail.com",
    "praveenkumar10105.e@gmail.com",
    "bilb@vtrac.com",
    "zaiga.puke@gmail.com",
    "12cislas@gmail.com",
    "ishu.gupta2003@gmail.com",
    "sisatmp+f1gef@gmail.com",
    "michaela.larmerova@gmail.com",
    "tailor23@mail.ru",
    "lucja.gryz@gmail.com",
    "stefan.buijsingh@businessmodelsinc.com",
    "natalia.galante@encora.com",
    "rimix3@gmail.com",
    "suriyaganesh097@gmail.com",
    "ireneusz.iskra@cargotec.com",
    "joseph@wshrecruitment.co.uk",
    "officesugarverse@gmail.com",
    "sugarverseio@gmail.com",
    "shvm.req@gmail.com",
    "siddanag@boston-technology.com",
    "anurag@mqtechsolutions.com",
    "rohitbhargava05@gmail.com",
    "isaac.recruiter8@gmail.com",
    "karthik.pentani@alissoftware.com",
    "anabela.dimoska@gmail.com",
    "seyeon8691@gmail.com",
    "eric@koddi.com",
    "kate.hotsyk@gmail.com",
    "ayubashir@aabnetworks.co.uk",
    "oliver@careermode.io",
    "sophiakaspriv@gmail.com",
    "kohei.yamada@techpit.jp",
    "ashapoval@insoft-global.com",
    "izzydennehy@ymail.com",
    "la.ha.m.d.a.bdu.l@gmail.com",
    "muneebraza167@gmail.com",
    "eddo1980@gmail.com",
    "dobrovajekaterina@gmail.com",
    "skaspriv@insoft-global.com",
    "shkolaprof@gmail.com",
    "kryptothecockapoo@gmail.com",
    "amit@vitoshainc.com",
    "lopeztamara84@gmail.com",
    "ryan@paragongroup.io",
    "bokovapremium@gmail.com",
    "jajab19412@alibrs.com",
    "sharon.gscmanila@gmail.com",
    "casate4166@namewok.com",
    "adementieva@insoft-global.com",
    "iboitmane@gmail.com",
    "clemence.odoux@mirakl.com",
    "pporcherot@yahoo.fr",
    "nssvkpraveen@gmail.com",
    "telyuhm1569@abv.bg",
    "philc@quaycrew.com",
    "alexandra.forsberg@wearemove.com",
    "ctimorason@service-professionals.com",
    "dhacker2019@gmail.com",
    "domien@soda.io",
    "togunsanya35@gmail.com",
    "oleksandra.rakomina@extreme-idea.com",
    "aaronberger@college.harvard.edu",
    "nshumsik@gmail.com",
    "chinmay@truefoundry.com",
    "kairosemmanuel06@gmail.com",
    "programasgenius@gmail.com",
    "adare.daly@gmail.com",
    "rishab@shuvel.net",
    "cogep24727@evvgo.com",
    "ratelek763@giratex.com",
    "petya@printt.com",
    "hqchrome@hirequotient.com",
    "pigagal421@giratex.com",
    "hicec81665@alibrs.com",
    "nicwood2004@gmail.com",
    "gareth.hanna90@gmail.com",
    "agnese.peetersone@gmail.com",
    "pauloferreira.clusterosl@gmail.com",
    "jqiao@college.harvard.edu",
    "julia.davies@rsmuk.com",
    "humancapitalabc@gmail.com",
    "katerina.likhno@gmail.com",
    "vaidehi.kodape@endress.com",
    "kuhu.sharma@techment.com",
    "prabhudesai.rohan@gmail.com",
    "megha.nair@simsr.edu.in",
    "recrutare@abchumancapital.ro",
    "sushobhanpriyadarshi@gmail.com",
    "tarekshabana2@gmail.com",
    "meera@stallionindia.com",
    "pascal.porcherot@pepite.link",
    "mariaclaramos.s@gmail.com",
    "ghawkes1215@gmail.com",
    "pincilaura@hotmail.com",
    "isobel@idvirtualassistant.com",
    "amanda@semicolontalent.com",
    "erfan377@gmail.com",
    "drew@50y.com",
    "jordan@adamslimitless.com",
    "asha.mistry@rsmuk.com",
    "alexpaniccia@gmail.com",
    "shafeekh@finanshels.com",
    "srishti@finanshels.com",
    "kliivan.38@abv.bg",
    "anne.tschirhart67@gmail.com",
    "annmoleda@gmail.com",
    "katerina-likhno@yandex.ru",
    "mercedesburges@gmail.com",
    "fengjiao@shortbread.ai",
    "shirij@marvell.com",
    "diana.mendesdasilva@stellantis.com",
    "dav.ed.al.en.a.re@gmail.com",
    "silvaedmundo2013@gmail.com",
    "mequer98@gmail.com",
    "cerveny.n@gmail.com",
    "da.m.ar.ik.o.h.en@gmail.com",
    "rwilliamsstvl@gmail.com",
    "bob@cognitivepath.io",
    "dan.gilliam@cannonsearch.com",
    "a.myrutenko@roosh.tech",
    "khrystyna@equallytalent.com",
    "vescamilla@dropbox.com",
    "bondarchuk.yaroslava@betterme.world",
    "natalie.obeirnes@kornferry.com",
    "marcotrindd@gmail.com",
    "diyer@innovationendeavors.com",
    "filipa.cluster@gmail.com",
    "vik1993@live.co.uk",
    "anandsai.1403@gmail.com",
    "cezar.ahmad@gmail.com",
    "santosh@shape.ci",
    "itwarriors.recruit@gmail.com",
    "ni.n.aaporti.a.n@gmail.com",
    "dominika.taton@clurgo.com",
    "amit.chaudhary2022@gmail.com",
    "godwin@bondtalentmanagement.agency",
    "boris@urbanlinker.com",
    "natalia.heras.1975@gmail.com",
    "skigcaliemus@gmail.com",
    "rhyshughes@google.com",
    "noel@noelwebb.ca",
    "yemijackson18@gmail.com",
    "hoplin.dev@gmail.com",
    "danny@channel.io",
    "bomij31166@tospage.com",
    "joanna.charkiewicz@hrestie.com",
    "omar.dhaka07@gmail.com",
    "tansu@wellbees.co",
    "nick.anushka@gmail.com",
    "tsoler24@gmail.com",
    "nourah.aloseimi@gmail.com",
    "vdr_tiffanyiglesiaak@airasia.com",
    "s@evenrank.com",
    "yagmur@codeway.co",
    "lasma@gravityteam.co",
    "thomas.soler@truv.com",
    "rspl.tusharp@gmail.com",
    "tusharpawar0601@gmail.com",
    "chefirst@gmail.com",
    "syrena.bayne@proforce.net.au",
    "kgonsowska@vp.pl",
    "anna.k.smyk@gmail.com",
    "albi.raden@abv.bg",
    "rozenbauma.l@gmail.com",
    "dianabilan13@gmail.com",
    "vodiye1372@ebuthor.com",
    "ebailey@solinkcorp.com",
    "t.pawar@rishabhbizsol.com",
    "thermkens@solinkcorp.com",
    "horse.fox011@gmail.com",
    "angelesherrera3@gmail.com",
    "laoseming55@gmail.com",
    "sailee.dorle@clairvoyantsoft.com",
    "melissa.munnich@woodhurst.com",
    "venkat.m@emergertech.com",
    "minsaopisey@gmail.com",
    "saks@tempr.email",
    "dianamendez.dms@gmail.com",
    "josufradejas@gmail.com",
    "xocomik237@ebuthor.com",
    "crvlucumi@gmail.com",
    "gaiapoggi@gmail.com",
    "deangela401@mygrovemail.com",
    "sara.piets@gmail.com",
    "ugutierrez@schubergphilis.com",
    "valentipaez@gmail.com",
    "khatunagujabidze@gmail.com",
    "noel@craftcapital.io",
    "josh@lunarholdco.com",
    "hillaryluvai18@gmail.com",
    "hillaryluvai19@gmail.com",
    "hartmann_antonia@web.de",
    "sekajas251@huizk.com",
  ],
};

const InsightsPage = () => {
  const userDetails = useHookstate(GlobalState.userDetails).get();

  return (
    <div className="accounts-main">
      <div className="accounts-top-area">
        <div className="accounts-heading">Welcome {userDetails.firstName}!</div>
        <div className="accounts-subheading">
          Here is the weekly update of analysis of your report on dashboard.
        </div>
      </div>
      <div className="accounts-table-main">
        <div style={{ fontWeight: "500", fontSize: "20px" }}>
          Free users who executed a filter search, but not vector search, and
          have reached free tier limit
        </div>
        <LeadsTable insightsEmails={insightEmails.notActivatedUsers} />
      </div>
    </div>
  );
};

export default InsightsPage;
