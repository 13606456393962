import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridRenderCellParams,
  GridToolbar,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import "./AccountsTable.scss";
import TableCellAccountName from "../TableCellComponents/TableCellAccountName";
import TableCellLastActivity from "../TableCellComponents/TableCellLastActivity";
import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { useHookstate } from "@hookstate/core";
import GlobalState from "../../global/GlobalStore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableCellAccountDetails from "../TableCellComponents/TableCellAccountDetails";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ApiCalls from "../../global/ApiCalls";
import AccountFilterPopover from "../FilterPopover/AccountFilterPopover";
import {
  AccountPageViewsType,
  ActivityGraphType,
  GlobalAccountFilterType,
  GlobalConfidenceScoreType,
  GlobalPaidPlanType,
  SESSION_TIME_MAX,
  TOTAL_VISITS_MAX,
} from "../../global/GlobalTypes";
import HelperFns from "../../global/HelperFns";
import DownloadIcon from "@mui/icons-material/Download";
import { ReactComponent as HubspotIcon } from "../../assets/svgs/hubspot-icon.svg";
import TableCellHubspotAdd from "../TableCellComponents/TableCellHubspotAdd";

const filterOperator = {
  label: "mainFilter",
  value: "mainFilter",
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams<AccountsTableRowType, any>): boolean => {
      if (filterItem.value === undefined) return true;
      if (params.row === undefined) return true;
      const filterItemValue = filterItem.value as GlobalAccountFilterType;

      const results: boolean[] = [];
      if (filterItemValue?.accountName?.length) {
        results.push(
          filterItemValue.accountName.includes(params.row.accountName)
        );
      }

      if (filterItemValue?.location?.length && params.row?.location?.length) {
        let localRes = false;
        for (let i in filterItemValue.location) {
          const location = filterItemValue.location[i];
          if (params.row.location.includes(location)) {
            localRes = true;
            break;
          }
        }
        results.push(localRes);
      }

      if (filterItemValue?.industry?.length) {
        results.push(filterItemValue.industry.includes(params.row.industry));
      }

      if (filterItemValue?.totalVisits?.length) {
        if (filterItemValue.totalVisits[1] === TOTAL_VISITS_MAX) {
          results.push(
            params.row.totalVisits >= filterItemValue.totalVisits[0]
          );
        } else if (
          params.row.totalVisits >= filterItemValue.totalVisits[0] &&
          params.row.totalVisits <= filterItemValue.totalVisits[1]
        ) {
          results.push(true);
        } else {
          results.push(false);
        }
      }

      if (filterItemValue?.sessionTime?.length) {
        const websiteDurationMins = params.row.totalWebsiteDuration / 60;
        if (filterItemValue.sessionTime[1] === SESSION_TIME_MAX) {
          results.push(websiteDurationMins >= filterItemValue.sessionTime[0]);
        } else if (
          websiteDurationMins >= filterItemValue.sessionTime[0] &&
          websiteDurationMins <= filterItemValue.sessionTime[1]
        ) {
          results.push(true);
        } else {
          results.push(false);
        }
      }

      if (filterItemValue?.planTiers?.length) {
        results.push(
          filterItemValue.planTiers.includes(params.row.planTypeObj?.name ?? "")
        );
      }

      if (filterItemValue?.confidenceScore?.length) {
        results.push(
          filterItemValue.confidenceScore.includes(
            params.row.confidenceScore.confidence ?? ""
          )
        );
      }

      if (filterItemValue?.accountPages?.length) {
        let localRes = false;
        for (let i in filterItemValue.accountPages) {
          const page = filterItemValue.accountPages[i];
          if (
            params.row.pageViews?.find((item) => item.path === page) !==
            undefined
          ) {
            localRes = true;
            break;
          }
        }
        results.push(localRes);
      }

      if (results.length === 0) return true;

      return results.every((result) => result);
      // return results.some((result) => result);
    };
  },
};

export type AccountsTableRowType = {
  id: string;
  accountName: string;
  accountWebsite: string;
  totalVisits: number;
  totalLeads: number;
  totalWebsiteDuration: number;
  lastActivity: Date;
  companySize: string;
  industry: string;
  companyFounded: string;
  linkedinUrl: string;
  location: string;
  activityData: ActivityGraphType[];
  isFavorite: boolean;
  logoUrl: string;
  planTypeObj?: GlobalPaidPlanType;
  totalEvents?: number;
  pageViews?: AccountPageViewsType[];
  confidenceScore: GlobalConfidenceScoreType;
};

let columns: GridColDef[] = [
  {
    field: "isFavorite",
    headerName: "",
    width: 10,
    filterable: false,

    renderCell: (params: GridRenderCellParams<any, boolean>) => {
      const handleClick = (e: any) => {
        ApiCalls.toggleAccountFavorite(params.row.id);
        e.stopPropagation();
      };
      return (
        <div
          className={`${
            params.value
              ? "a-table-data-grid-cell-fav-show"
              : "a-table-data-grid-cell-fav"
          }`}
          onClick={handleClick}
        >
          {params.value ? <StarIcon /> : <StarBorderIcon />}
        </div>
      );
    },
  },
  {
    field: "id",
    headerName: "#",
    width: 50,
    filterable: false,
    filterOperators: [filterOperator],
    renderCell: (index) => (
      <div className="a-table-data-grid-cell">
        {index.api.getAllRowIds().indexOf(index.id) + 1}
      </div>
    ),
  },
  {
    field: "accountName",
    headerName: "Account",
    width: 250,

    renderCell: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return (
        <TableCellAccountName
          accountName={params.row?.accountName ?? ""}
          accountWebsite={params.row?.accountWebsite ?? ""}
          accountId={params.row.id}
          logoUrl={params.row.logoUrl}
        />
      );
    },
  },
  {
    field: "companyFirmographic",
    headerName: "Details",
    minWidth: 250,
    flex: 1,
    valueGetter: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return params.row.companySize;
    },
    renderCell: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      if (!params.row) return <div></div>;
      return (
        <TableCellAccountDetails
          companySize={params.row.companySize}
          industry={params.row.industry}
          companyFounded={params.row.companyFounded}
          location={params.row.location}
          linkedinUrl={params.row.linkedinUrl}
        />
      );
    },
  },
  {
    field: "planTypeObj",
    headerName: "Plan",
    minWidth: 70,
    renderCell: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return (
        <div className="a-table-data-grid-cell">
          {params.row.planTypeObj ? (
            <Chip
              label={params.row.planTypeObj.name}
              size="small"
              color="primary"
              sx={{
                fontSize: "inherit",
                backgroundColor: params.row.planTypeObj.color,
                color: "#000",
                fontWeight: "bold",
              }}
            />
          ) : (
            ""
          )}
        </div>
      );
    },
  },
  {
    field: "totalEvents",
    headerName: "Events",
    minWidth: 50,

    renderCell: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return (
        <div className="a-table-data-grid-cell">{params.row.totalEvents}</div>
      );
    },
  },
  {
    field: "totalLeads",
    headerName: "Leads",
    width: 50,

    renderCell: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return (
        <div className="a-table-data-grid-cell">{params.row.totalLeads}</div>
      );
    },
  },
  {
    field: "totalVisits",
    headerName: "Visits",
    width: 100,
    renderCell: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return (
        <div className="a-table-data-grid-cell">
          {params.row.totalVisits} visits
          <br />
          <div style={{ fontSize: "10px" }}>
            {HelperFns.convertSecsToString(params.row.totalWebsiteDuration)}
          </div>
        </div>
      );
    },
  },
  {
    field: "confidenceScore",
    headerName: "Confidence",
    width: 100,
    valueGetter: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return params.row.confidenceScore.score;
    },
    renderCell: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return (
        <div className="a-table-data-grid-cell">
          {params.row.confidenceScore.confidence}
        </div>
      );
    },
  },
  {
    field: "lastActivity",
    headerName: "Last Activity",
    width: 100,
    renderCell: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return (
        <TableCellLastActivity
          lastActivity={params.row.lastActivity ?? new Date()}
        />
      );
    },
  },

  {
    field: "activityData",
    headerName: "Activity (Last 7 days)",
    width: 150,
    valueGetter: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return params.row.activityData.length;
    },
    renderCell: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return (
        <ResponsiveContainer width="100%" height="50%">
          <AreaChart
            width={150}
            height={60}
            data={params.row.activityData ?? []}
          >
            <defs>
              <linearGradient id="colorUv" x1="1" y1="1" x2="1" y2="0">
                <stop offset="0%" stopColor="#8780FF" stopOpacity={0} />
                <stop offset="100%" stopColor="#8780FF" stopOpacity={1} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="count"
              strokeWidth={1}
              stroke="#8780FF"
              fill="url(#colorUv)"
              isAnimationActive={false}
            />
          </AreaChart>
        </ResponsiveContainer>
      );
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    renderCell: (params: GridRenderCellParams<AccountsTableRowType, any>) => {
      return <TableCellHubspotAdd accountId={params.row.id} />;
    },
  },
];

const AccountsTable = () => {
  const accountsTableRows = useHookstate(GlobalState.accountsTableRows).get({
    noproxy: true,
  });
  const globalAccountFilterState = useHookstate(GlobalState.accountsFilter);
  const globalAccountFilter = globalAccountFilterState.get();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false);
  const savedAccountFilters = useHookstate(GlobalState.savedAccountFilters);
  const [savedAccountFilterButtonText, setSavedAccountFilterButtonText] =
    useState<string>("Save Filter");
  const [selectedAccountFilterName, setSelectedAccountFilterName] =
    useState<string>("");

  const globalConfig = useHookstate(GlobalState.config).get()?.configuration;
  const settings = useHookstate(GlobalState.settings).get();

  if (!globalConfig?.isPLG) {
    columns = columns.filter((col) => {
      return (
        col.field !== "planTypeObj" &&
        col.field !== "totalLeads" &&
        col.field !== "totalEvents"
      );
    });
  }

  if (!settings.isHubspotConnected) {
    columns = columns.filter((col) => {
      return col.field !== "actions";
    });
  }

  const handleRowClick = (params: any) => {
    navigate(`/account/${params.id}`);
  };

  const handleFilterButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setFilterPopoverOpen(!filterPopoverOpen);
  };

  const getVisitFilterChipText = () => {
    if (globalAccountFilter?.totalVisits?.length) {
      if (
        globalAccountFilter.totalVisits[1] < TOTAL_VISITS_MAX &&
        globalAccountFilter.totalVisits[0] > 0
      ) {
        return `Visits: ${globalAccountFilter.totalVisits[0]} - ${globalAccountFilter.totalVisits[1]}`;
      } else if (globalAccountFilter.totalVisits[1] < TOTAL_VISITS_MAX) {
        return `Visits: < ${globalAccountFilter.totalVisits[1] + 1}`;
      } else if (globalAccountFilter.totalVisits[0] > 0) {
        return `Visits: > ${globalAccountFilter.totalVisits[0]}`;
      }
    }
    return "";
  };

  const getSessionTimeFilterChipText = () => {
    if (globalAccountFilter?.sessionTime?.length) {
      if (
        globalAccountFilter.sessionTime[1] < SESSION_TIME_MAX &&
        globalAccountFilter.sessionTime[0] > 0
      ) {
        return `Session: ${globalAccountFilter.sessionTime[0]} mins - ${globalAccountFilter.sessionTime[1]} mins`;
      } else if (globalAccountFilter.sessionTime[1] < SESSION_TIME_MAX) {
        return `Session: < ${globalAccountFilter.sessionTime[1] + 1} mins`;
      } else if (globalAccountFilter.sessionTime[0] > 0) {
        return `Session: > ${globalAccountFilter.sessionTime[0]} mins`;
      }
    }
    return "";
  };

  const handleSaveFilterNameChange = (event: any, newValue: string | null) => {
    if (!newValue) {
      setSavedAccountFilterButtonText("Save Filter");
      setSelectedAccountFilterName("");
      return;
    }
    const savedFilterNames = savedAccountFilters
      .get()
      .map((filter) => filter.filterName);
    setSelectedAccountFilterName(newValue);
    if (savedFilterNames.includes(newValue)) {
      setSavedAccountFilterButtonText("Update Filter");
    } else {
      setSavedAccountFilterButtonText("Save Filter");
    }
  };

  const handleSaveFilterButton = async () => {
    if (!selectedAccountFilterName) return;

    const filterString = JSON.stringify(
      GlobalState.accountsFilter.get({ noproxy: true })
    );

    await ApiCalls.saveFilter({
      filterName: selectedAccountFilterName,
      filterString: filterString,
    });
  };

  const loadSavedFilter = (filterName: string) => {
    const filterString = savedAccountFilters
      .get()
      .find((filter) => filter.filterName === filterName)?.filterString;
    if (filterString) {
      globalAccountFilterState.set(JSON.parse(filterString));
    }
    setSelectedAccountFilterName(filterName);
  };

  const apiRef = useGridApiRef();

  return (
    <div className="a-table">
      <div className="a-table-filters">
        <Button onClick={handleFilterButtonClick}>Open Filter</Button>
        <AccountFilterPopover
          anchorEl={anchorEl}
          open={filterPopoverOpen}
          handleClose={() => {
            setFilterPopoverOpen(false);
          }}
        />
        <div style={{ flex: 1 }}>
          {globalAccountFilter?.accountName?.map((name) => {
            return (
              <Chip
                label={name}
                sx={{ mr: 1, backgroundColor: "#e8eeff" }}
                onDelete={() => {
                  globalAccountFilterState.accountName.set(
                    (globalAccountFilter.accountName ?? []).filter(
                      (item) => item !== name
                    )
                  );
                }}
              />
            );
          })}
          {globalAccountFilter?.location?.map((name) => {
            return (
              <Chip
                label={name}
                sx={{ mr: 1, backgroundColor: "#BCEEBB" }}
                onDelete={() => {
                  globalAccountFilterState.location.set(
                    (globalAccountFilter.location ?? []).filter(
                      (item) => item !== name
                    )
                  );
                }}
              />
            );
          })}
          {globalAccountFilter?.industry?.map((name) => {
            return (
              <Chip
                label={name}
                sx={{ mr: 1, backgroundColor: "#EED0BB" }}
                onDelete={() => {
                  globalAccountFilterState.industry.set(
                    (globalAccountFilter.industry ?? []).filter(
                      (item) => item !== name
                    )
                  );
                }}
              />
            );
          })}
          {globalAccountFilter?.planTiers?.map((name) => {
            return (
              <Chip
                label={name}
                sx={{ mr: 1, backgroundColor: "#EED0BB" }}
                onDelete={() => {
                  globalAccountFilterState.planTiers.set(
                    (globalAccountFilter.planTiers ?? []).filter(
                      (item) => item !== name
                    )
                  );
                }}
              />
            );
          })}
          {globalAccountFilter?.totalVisits?.length ? (
            <Chip
              label={getVisitFilterChipText()}
              sx={{ mr: 1, backgroundColor: "#E1BBEE" }}
              onDelete={() => {
                globalAccountFilterState.totalVisits.set(undefined);
              }}
            />
          ) : null}
          {globalAccountFilter?.sessionTime?.length ? (
            <Chip
              label={getSessionTimeFilterChipText()}
              sx={{ mr: 1, backgroundColor: "#EEECBB" }}
              onDelete={() => {
                globalAccountFilterState.sessionTime.set(undefined);
              }}
            />
          ) : null}
          {globalAccountFilter?.accountPages?.map((name) => {
            return (
              <Chip
                label={name.length > 20 ? name.slice(0, 20) + "..." : name}
                sx={{ mr: 1, backgroundColor: "#e8eeff" }}
                onDelete={() => {
                  globalAccountFilterState.accountPages.set(
                    (globalAccountFilter.accountPages ?? []).filter(
                      (item) => item !== name
                    )
                  );
                }}
              />
            );
          })}
          {globalAccountFilter?.confidenceScore?.map((name) => {
            return (
              <Chip
                label={name}
                sx={{ mr: 1, backgroundColor: "#EED0BB" }}
                onDelete={() => {
                  globalAccountFilterState.confidenceScore.set(
                    (globalAccountFilter.confidenceScore ?? []).filter(
                      (item) => item !== name
                    )
                  );
                }}
              />
            );
          })}
        </div>

        <Autocomplete
          id="account-page-filter"
          options={savedAccountFilters.get().map((filter) => filter.filterName)}
          value={selectedAccountFilterName}
          renderInput={(params) => (
            <TextField {...params} label="Filter Name" />
          )}
          onInputChange={handleSaveFilterNameChange}
          onChange={(event, newValue) => {
            if (newValue) {
              loadSavedFilter(newValue);
            }
          }}
          freeSolo
          getOptionLabel={(option) => option}
          size="small"
          ListboxProps={{
            sx: { fontSize: 12 },
          }}
          sx={{
            minWidth: "150px",
            maxWidth: "400px",
            "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
              fontSize: 12,
            },
            "& .MuiAutocomplete-tag": {
              fontSize: 12,
            },
          }}
        />
        <Button onClick={handleSaveFilterButton}>
          {savedAccountFilterButtonText}
        </Button>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            const filteredSortedIds = gridFilteredSortedRowIdsSelector(apiRef);
            HelperFns.downloadAccountsToCSV(
              HelperFns.getArrayFromState(accountsTableRows).filter((item) =>
                filteredSortedIds.includes(item.id)
              )
            );
          }}
        >
          <DownloadIcon />
        </span>
      </div>

      <DataGrid
        rows={accountsTableRows}
        columns={columns}
        rowHeight={60}
        apiRef={apiRef}
        sx={{
          maxHeight: "calc(100vh - 120px)",
          "&&.MuiDataGrid-root .MuiDataGrid-cell": {
            border: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .Mui-hovered": {
            cursor: "pointer",
            backgroundColor: "#F6F8FA !important",
          },
          "& .Mui-hovered .a-table-data-grid-cell-fav": {
            display: "block",
          },
          "& .MuiDataGrid-row": {
            borderBottom: "#DBE3EA 1px solid",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#F6F8FA !important",

            border: "none",
            borderRadius: 2,
          },
          "& .MuiDataGrid-columnHeader--filtered button": {
            display: "none",
          },
        }}
        className="a-table-data-grid"
        disableRowSelectionOnClick
        onRowClick={handleRowClick}
        filterModel={{
          items: [
            {
              id: 1,
              field: "id",
              operator: "mainFilter",
              value: globalAccountFilter,
            },
          ],
        }}
      />
    </div>
  );
};

export default AccountsTable;
