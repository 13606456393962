import React from "react";
import "./CardCustom.scss";

type CardCustomPropTypes = {
  children: React.ReactNode;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const CardCustom = (props: CardCustomPropTypes) => {
  const { children, ...otherProps } = props;
  const newClassNames = "card-custom " + otherProps.className;
  return (
    <div {...otherProps} className={newClassNames}>
      {props.children}
    </div>
  );
};

export default CardCustom;
